import {scrollbarSetToTop} from  "../components/Helper"
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function AboutUs(){
    useEffect(() => {
        scrollbarSetToTop(); 
   }, [])
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | About Us</title>
        </Helmet>
        <section className="bg-blue py-4 position-relative overflow-hidden">
            <img src="images/courses-bg.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="weteach"/>
            <div className="container position-relative zindex-2">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="heading-lines text-white m-0 pt-3">About Us</h2>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-section py-5">
            <div className="container py-sm-4">
                <div className="row justify-content-center">
                    <div className="col-sm-8 text-center">
                        <h2 className="fw-bold mb-5">Why Choose Us ?</h2>
                        {/* <p className="opacity-75 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                    </div>
                </div>
                <div className="row g-4">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="card-tiles gredient-orange text-white p-3 p-sm-4 text-center rounded-15 h-100">
                            <i className="bi-person-workspace fs-65px lh-1"></i>
                            <h4 className="m-0 py-4 fw-bold">Teacher</h4>
                            <p className="m-0 text-start">At “We Teach” we have a whole team of experienced teachers of all courses and classes. Our educators would provide full support to students as their expertise exceed their reputation. They develop the latest strategies ensuring that they get good grades without feeling any stress and improving performance throughout the year.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="card-tiles gredient-green text-white p-3 p-sm-4 text-center rounded-15 h-100">
                            <i className="bi-mortarboard fs-65px lh-1"></i>
                            <h4 className="m-0 py-4 fw-bold">Education</h4>
                            <p className="m-0 text-start">“We Teach” makes the learning experience relaxing, quick and affordable where all the online teaching tools are available for impactful learning. Higher education is provided on this online teaching platform where there is a 24-hour digital learning environment available.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="card-tiles gredient-blue text-white p-3 p-sm-4 text-center rounded-15 h-100">
                            <i className="bi-book fs-65px lh-1"></i>
                            <h4 className="m-0 py-4 fw-bold">Resources</h4>
                            <p className="m-0 text-start">With our best educational resources; study guides, lecture notes, practice exams, and supplement instructions are available for students who can access them at any time upon their availability.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="card-tiles gredient-purple text-white p-3 p-sm-4 text-center rounded-15 h-100">
                            <i className="bi-award fs-65px lh-1"></i>
                            <h4 className="m-0 py-4 fw-bold">Trust</h4>
                            <p className="m-0 text-start">We abide by the latest educational standards and to keep up with the system we have introduced our online educational platform; “We Teach" which is a UAE registered company providing high-quality educational services all over the world.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default AboutUs