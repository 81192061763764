import React, { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import { fabric } from 'fabric';
import samplePDF from "./pdf.pdf";

function PDFViewer() {
  const [numPages, setNumPages] = useState(null);
  const [currentPage] = useState(1);
  const [activeTool, setActiveTool] = useState('pencil');
  const pdfPageRefs = useRef([]);
  const drawingCanvasRefs = useRef([]);
  const currentPageRef = useRef(1);

  // Maintain shapes for each page and tool
  const shapesByPageAndTool = useRef({});

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageLoad = (pageNumber) => {
    // Create refs for PDF pages and their corresponding drawing canvases
    pdfPageRefs.current[pageNumber - 1] = React.createRef();
    drawingCanvasRefs.current[pageNumber - 1] = React.createRef();

    // Initialize drawing based on the active tool
    initializeDrawing(pageNumber);
  };

  const initializeDrawing = (pageNumber) => {
    if (!drawingCanvasRefs.current[pageNumber - 1]) {
      return;
    }
    const fabricCanvas = new fabric.Canvas(drawingCanvasRefs.current[pageNumber - 1].current, {
      selection: false,
    });

    if (!shapesByPageAndTool.current[pageNumber]) {
      shapesByPageAndTool.current[pageNumber] = {
        pencil: [],
        rectangle: [],
      };
    }

    // Set the canvas background to white (or any desired background color)
    fabricCanvas.backgroundColor = 'white';
    fabricCanvas.renderAll();

    // Load existing shapes for the current tool
    fabricCanvas.loadFromJSON(shapesByPageAndTool.current[pageNumber][activeTool], () => {
      fabricCanvas.renderAll();
    });

    if (activeTool === 'pencil') {
      fabricCanvas.isDrawingMode = true;
      fabricCanvas.freeDrawingBrush.width = 5;
      fabricCanvas.freeDrawingBrush.color = 'black';
    } else if (activeTool === 'rectangle') {
      fabricCanvas.on('mouse:down', (options) => {
        if (options.e.button === 1) return; // Middle mouse button (usually) for scrolling
        const pointer = fabricCanvas.getPointer(options.e);

        // Create a rectangle on click
        const rect = new fabric.Rect({
          left: pointer.x - 50,
          top: pointer.y - 50,
          width: 100,
          height: 100,
          fill: 'transparent',
          stroke: 'red',
          strokeWidth: 2,
        });

        // Add the rectangle to the canvas and save it in the shapes list
        fabricCanvas.add(rect);
        shapesByPageAndTool.current[pageNumber][activeTool].push(rect);
        fabricCanvas.renderAll();
      });
    }
  };

  useEffect(() => {
    // Update the current page reference after the component mounts
    currentPageRef.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    // Initialize drawing when the active tool changes
    for (let i = 0; i < numPages; i++) {
      initializeDrawing(i + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTool, numPages]);

  return (
    <div>
      <div>
        <button onClick={() => setActiveTool('pencil')}>Pencil Tool</button>
        <button onClick={() => setActiveTool('rectangle')}>Rectangle Tool</button>
      </div>
      <Document
        file={samplePDF}
        onLoadSuccess={handleLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            onLoadSuccess={() => onPageLoad(index + 1)}
            width={800} // Set the desired width
            inputRef={(ref) => {
              // Set the PDF page reference
              pdfPageRefs.current[index] = ref;
              // Make sure the current page is set when the ref becomes available
              if (index + 1 === currentPageRef.current) {
                currentPageRef.current = index + 1;
              }
            }}
          >
            {/* Create a separate canvas for each page */}
            <canvas
              ref={drawingCanvasRefs.current[index]}
              width={912}
                            height={1188}
                            className="pdf-canvas"
                            style={{border: "1px solid black", zIndex: 10, position: "absolute", top: "0rem"}}
            />
          </Page>
        ))}
      </Document>
      <p>
        Page {currentPage} of {numPages}
      </p>
    </div>
  );
}

export default PDFViewer;
