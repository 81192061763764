import {fetchData, showAlert, scrollbarSetToTop} from "../components/Helper";
import {useEffect, useState, useContext} from "react";
import Context from "../components/Context";
import {useParams} from "react-router";
import {Link, useNavigate} from "react-router-dom";
import _ from "lodash";
import {Helmet} from "react-helmet";
import {Modal, Button} from "react-bootstrap";

function JoinCourse() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [context] = useContext(Context);
    const courseId = useParams().course_id;
    const [data, setData] = useState("");
    const [joinType, setJoinType] = useState("class");
    const navigate = useNavigate();

    useEffect(() => {
        scrollbarSetToTop();
        let student_id = context && context.auth ? context.auth.id : false;
        fetchData(
            `courses-details/${courseId}?student_id=${student_id}`,
            "GET",
            "",
            true,
            false,
            (res) => {
                if (res.status && res.data) {
                    setData(res.data);
                    setJoinType(
                        res.data.class_price === "EGP 0.00" || res.data.class_price === "EGP 0" ? "course" : "class"
                    );
                }
            },
            (err) => {}
        );
    }, [context, courseId]);

   

    const joinCourse = () => {
        if (context) {
            let formData = {
                student_id: context.auth.id,
                course_id: courseId,
                join_type: joinType,
                course_name: data.name,
                level_name: data.level_name,
                teacher_name: data.teacher_name,
            };
            fetchData(
                `join-courses`,
                "POST",
                formData,
                true,
                false,
                (res) => {
                    if (res.status && res.data) {
                       setShow(false)
                        setTimeout(() => {
                            navigate("/my-subjects");
                        }, 1000);
                    }
                },
                (err) => {}
            );
        }
    };
    const checkUser = () => {
        if (context && context.auth && context.auth.role_id !== 6) {
            let msg = {message: "Please register as a student to join the course.", status: false};
            showAlert(msg);
        } else {
            setShow(true);
            // $("#joinModal").modal("show");
        }
    };

    // const laterBtn = () => {
    //     $("#joinModal").modal("hide");
    //     navigate(`/course/${courseId}/join`);
    // };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Join Course</title>
            </Helmet>
            <section className="bg-blue py-4 position-relative overflow-hidden">
                <img
                    src="/images/courses-bg.png"
                    className="position-absolute top-0 opacity-25 zindex-0 pointer-none"
                    alt="weteach"
                />
                <div className="container position-relative zindex-2">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="heading-lines text-white m-0 pt-3">Join Course</h2>
                        </div>
                    </div>
                </div>
            </section>
            {data ? (
                <section className="cources-section py-5">
                    <div className="container pb-sm-5">
                        <div className="row g-4">
                            <div className="col-lg-8">
                                <h2 className="text-orange mb-4">
                                    {data.name} {data.level_name} ({data.teacher_name})
                                </h2>

                                <div className="row g-2">
                                    <div className="col-md-12">
                                        <div className="course-info p-3 border-lightblue rounded-10">
                                            <i className="bi-file-earmark-text"></i>
                                            <div className="course-typo">
                                                <small>Description</small>
                                                <div className="m-0 fs-17px">{data.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="course-info p-3 border-lightblue rounded-10">
                                            <i className="bi-watch"></i>
                                            <div className="course-typo l1 w-100">
                                                <small>Timing (Cairo Local Time)</small>
                                                <div className="fs-17px row g-1">
                                                    {data.class_day
                                                        ? data.class_day.map((subject_detail, index) => {
                                                              return index < 4 ? (
                                                                  <div key={index} className="d-block col-md-6">
                                                                      <span className="text-orange">
                                                                          {_.capitalize(subject_detail.day)}{" "}
                                                                      </span>
                                                                      {subject_detail.start_time} to{" "}
                                                                      {subject_detail.end_time}
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              );
                                                          })
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="course-info p-3 border-lightblue rounded-10">
                                            <i className="bi-person-badge"></i>
                                            <div className="course-typo">
                                                <small>Tutor</small>
                                                <div className="m-0 fs-17px">
                                                    <Link
                                                        to={`/teacher-info/${data.teacher_id}`}
                                                        className="text-decoration-none text-orange"
                                                    >
                                                        {data.teacher_name}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="course-info p-3 border-lightblue rounded-10">
                                            <svg className="bi bi-egp">
                                                <use href="#bi_egp"></use>
                                            </svg>

                                            <div className="course-typo l1">
                                                <small>Price</small>
                                                <div className="m-0 fs-17px">
                                                    {data &&
                                                    (data.class_price === "EGP 0.00" || data.class_price === "EGP 0")
                                                        ? ""
                                                        : `${data.class_price} (Per Class)`}
                                                </div>
                                                <div className="m-0 fs-17px">
                                                    {data &&
                                                    (data.course_price === "EGP 0.00" || data.course_price === "EGP 0")
                                                        ? ""
                                                        : `${data.course_price} (Per Course)`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="course-info p-3 border-lightblue rounded-10">
                                            <i className="bi-file-earmark-pdf"></i>
                                            <div className="course-typo l1">
                                                <small>Assignment</small>
                                                <div className="m-0 fs-17px">Weekly</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="course-info p-3 border-lightblue rounded-10">
                                            <i className="bi-record-circle"></i>
                                            <div className="course-typo">
                                                <small>Recording</small>
                                                <div className="m-0 fs-17px">
                                                    Available <strong className="text-green">&#10003;</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="text-center shadow-sm border-lightblue p-4 rounded-10 h-100">
                                    <div className="ratio ratio-1x1 mmw-140px rounded overflow-hidden mx-auto shadow-sm">
                                        <img src={data.teacher_image} className="img-cover" alt="weteach" />
                                    </div>
                                    <h4 className="py-3 m-0">{data.teacher_name}</h4>
                                    <p className="h6 lh-base opacity-75 mb-4">{data.teacher_description}</p>
                                    {context && context.auth ? (
                                        data.join ? (
                                            <Link
                                                to="/my-subjects"
                                                className="btn btn-orange btn-lg px-4 px-lg-5"
                                                title="Subject"
                                            >
                                                Subject
                                            </Link>
                                        ) : (
                                            <button
                                                type="button"
                                                className="btn btn-orange btn-lg px-4 px-lg-5"
                                                onClick={checkUser}
                                                title="Join"
                                            >
                                                Join{" "}
                                            </button>
                                        )
                                    ) : (
                                        <Link
                                            to="/sign-up"
                                            className="btn btn-orange btn-lg px-4 px-lg-5"
                                            title="Register"
                                        >
                                            Register
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} backdrop="static" className="center_modal">
                        <Modal.Body>
                            <div className="row">
                                <div className="col-sm-12 text-center pb-2">
                                    {/* <i className="bi-watch display-1 text-blue"></i> */}
                                    <i className="bi bi-link-45deg display-1 text-blue"></i>
                                    <p className="text-muted px-4 py-3 lh-base fs-6 fs-17px mb-1">
                                        Dear <strong>{context && context.auth ? context.auth.name : ""}</strong> you are
                                        about to join{" "}
                                        <strong>
                                            {data.name} {data.level_name} ({data.teacher_name})
                                        </strong>{" "}
                                        taught by <strong>{data.teacher_name ? data.teacher_name : ""}</strong> , Ready
                                        to join?
                                    </p>
                                    <div className="col text-center pb-4">
                                        {data.class_price === "EGP 0.00" || data.class_price === "EGP 0" ? (
                                            ""
                                        ) : (
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="join_type"
                                                    value="class"
                                                    id="per_class"
                                                    defaultChecked
                                                    onChange={(e) => setJoinType(e.target.value)}
                                                />
                                                <label className="form-check-label" htmlFor="per_class">
                                                    Join per class
                                                </label>
                                            </div>
                                        )}
                                        {data.course_price === "EGP 0.00" || data.course_price === "EGP 0" ? (
                                            ""
                                        ) : (
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="join_type"
                                                    value="course"
                                                    id="per_course"
                                                    defaultChecked={
                                                        data.class_price === "EGP 0.00" || data.class_price === "EGP 0"
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => setJoinType(e.target.value)}
                                                />
                                                <label className="form-check-label" htmlFor="per_course">
                                                    Join per course
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                    <Button
                                        variant="transparent"
                                        title="Later"
                                        onClick={handleClose}
                                        className="btn btn-outline-orange me-2 minw-100px"
                                    >
                                        Later
                                    </Button>

                                    {(data.class_price === "EGP 0.00" || data.class_price === "EGP 0") &&
                                    (data.course_price === "EGP 0.00" || data.course_price === "EGP 0") ? (
                                        ""
                                    ) : (
                                        <Button
                                            variant="transparent"
                                            className="btn btn-blue ms-2 minw-100px"
                                            title="Join Now"
                                            onClick={joinCourse}
                                        >
                                            Join Now
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </section>
            ) : (
                ""
            )}
        </>
    );
}

export default JoinCourse;
