import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { createRoot } from 'react-dom/client';
import { useState } from "react";
import Datatables, { initDataTable, reloadDataTable } from '../../components/Datatables';
import { fetchData } from "../../components/Helper";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function FeedbackList() {
    const navigate = useNavigate()
    const [dt] = useState({
        dt_url: `feedback-list`,
        dt_name: 'feedback-list',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"image", name:'image',title: 'Profile' , width:130, class:'table-img'},
            { data:"name", name:'name', title:"Name", class:"text-nowrap"},
            { data:"email", name:'email', title:"Email", class:"text-nowrap"},
            { data:"phone", name:'phone', title:"Phone", class:"text-nowrap"},
            { data:"account_name", name:'users.name', title:"Teacher"},
            { data:"courese_name", name:'courses.name', title:"Course"}, 
            { data:"status", name:'status',  title:"Status" },
            { data:"description", name:'description',  title:"Description" },
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-center text-nowrap", width:160}
        ],
        dt_column_defs: [
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                    { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' } 
                    </>)
                },
            },
            {   
                targets: 9,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<> 
                    
                        { rowData.status === 'inactive' ? 
                        <button type="button" className="btn btn-success py-2px m-2px w-100px" title="Activate" onClick={ () => handleFeedbackStatus('active', rowData.id)}>
                                Activate
                        </button>
                        : '' }

                        { rowData.status === 'active' ?
                        <button type="button" className="btn btn-danger py-2px m-2px w-100px" title="Deactivate" onClick={ () => handleFeedbackStatus('inactive', rowData.id)}>
                            Deactivate
                        </button>
                        : '' } 

                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" onClick={() => editFeedback(rowData.id)}>
                            Edit
                        </button> 

                        <button type="button" className="btn btn-danger py-2px m-2px" title="Delete" onClick={() => handleFeedbackStatus('delete',rowData.id)}>
                            Delete
                        </button>                             
                    </>)
                },
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                        <div className="table-imgbox table-img60">
                            <img src={rowData.image} alt="weteach"/>
                        </div>
                    </>)
                },
            },
        ]
    })

    const handleFeedbackStatus = (status, id) => { 
        fetchData('feedback/change-status', 'POST', {'status':status,'id':id}, true, false, (res) => {  
            reloadDataTable(dt)
        }, (err) => {}) 
    }

    const editFeedback =  (id) =>{ 
        navigate(`/feedback/edit/${id}`)
    }

    useEffect(() => {
        initDataTable(dt)
    }, [dt])
   
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Feedback</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Feedback</h5>
                        </div>
                        <div className="col-auto mb-2">
                            <Link to="/feedback/create" className="btn btn-blue mb-1" title='Add Feedback'>Add Feedback</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <div className="table-responsive p-1"> 
                                      <Datatables dt_name="feedback-list"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeedbackList
