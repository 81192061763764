import {scrollbarSetToTop} from  "../components/Helper"
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Faq(){
    useEffect(() => {
        scrollbarSetToTop(); 
   }, [])
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Faq</title>
        </Helmet>
        <section className="bg-blue py-4 position-relative overflow-hidden">
            <img src="images/courses-bg.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="weteach"/>
            <div className="container position-relative zindex-2">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="heading-lines text-white m-0 pt-3">Faq</h2>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-section py-5">
            <div className="container py-sm-4">
                <div className="row g-4">
                    <div className="col-sm-12 align-justify">
                        <h5 className="fw-bold">1- What Makes We Teach Outstanding Platform?</h5>
                        <p>Choosing We Teach for your child's online education is a smart decision for several compelling reasons:</p>

                        <h6 className="fw-bold">1. Experience our cutting-edge Instant Notification System</h6>
                        <p>We've developed a customized Telegram bot that keeps you seamlessly connected to your child's educational journey. This system delivers real-time updates, ensuring you stay informed about every aspect of the course, from class join notifications to your child's performance insights.</p>

                        <h6 className="fw-bold">2. Diverse Course Options</h6>
                        <p>We offer a wide range of courses, ensuring your child has access to the subjects they need or are interested in.</p>

                        <h6 className="fw-bold">3. Flexible Payments</h6>
                        <p>Our flexible payment options, including installment plans, make high-quality education more affordable and manageable for parents.</p>

                        <h6 className="fw-bold">4. Trial Classes</h6>
                        <p>You can rest assured about the teaching quality. We provide free trial classes and recorded sessions, allowing your child to experience the teaching style before committing.</p>

                        <h6 className="fw-bold">5. Experienced Teachers</h6>
                        <p>Our platform boasts experienced teachers who are dedicated to providing the best learning experience for your child.</p>

                        <h6 className="fw-bold">6. Teacher Variety</h6>
                        <p>In case your child doesn't feel comfortable with a teacher, we can easily switch them to a different one.</p>

                        <h6 className="fw-bold">7. Promotions and Discounts</h6>
                        <p>We frequently offer promotions, such as early bird discounts and group discounts, providing additional value for your investment.</p>

                        <h6 className="fw-bold">8. Convenient Access</h6>
                        <p>With recordings available shortly after classes, your child can review and catch up on missed lessons at their own pace.</p>

                        <h6 className="fw-bold">9. Parental Support</h6>
                        <p>Our platform offers peace of mind with features like class recordings, detailed course information, and the ability to connect with teachers or administrators.</p>

                        <h6 className="fw-bold">10. Transparent Pricing</h6>
                        <p>You'll only be charged the amount you see at checkout, with no hidden fees.</p>

                        <h6 className="fw-bold">11. Accessibility</h6>
                        <p>Our platform is accessible in multiple GCC countries, making it convenient for a wider range of students.</p>

                        <p>In summary, We Teach combines flexibility, quality, and affordability to provide a top-notch online education platform for your child. It's the ideal choice for parents who want the best for their children's education.</p>
                                                
                    </div>
                    <div className="col-sm-12 align-justify">
                        <h5 className="fw-bold">2- How To Sign Up On We Teach?</h5>
                        <p>To sign up on We Teach, follow these steps:</p>
                        <ol>
                            <li> Go to the 'Sign Up' section.</li>
                            <li> Start by entering your student account details.</li>
                            <li> Then, provide your parent account details.</li>
                            <li> You'll receive a verification email; make sure to verify your email first.</li>
                            <li> Once verified, you can log in to the platform.</li>
                        </ol>
                        <p>That's all you need to do to create your We Teach account! You can find help video <a href="https://www.youtube.com/watch?v=d47xwIxPqOE&t=4s" target="_blank">here</a>.</p>
                    </div>
                    <div className="col-sm-12 align-justify">
                        <h5 className="fw-bold">3- How Do I Enroll To A Course?</h5>
                        <p>To enroll in a course, follow these simple steps:</p>
                        <ol>
                            <li> Log in to your student account.</li>
                            <li> Look for the course you want in the 'Courses' section.</li>
                            <li> Click on the course you're interested in.</li>
                            <li> Press the 'Join' button.</li>
                        </ol>
                        <p>That's it! You're now enrolled in the course. However, if you have any further questions, feel free to ask us!</p>
                    </div>
                        
                    <div className="col-sm-12 align-justify">
                        <h5 className="fw-bold">4- What Are The Payment Options?</h5>
                        <p>We provide a range of payment options to make it convenient for you. You can pay by credit/debit card, use Apple Pay or Google Pay, or opt for a mobile wallet (with a 1% extra fee). We also offer the flexibility to pay in installments, with up to 8 installments available through 3rd party, and Fawry Pay as well.</p>

                        <h5 className="fw-bold">5- How To Pay For A Course?</h5>
                        <p>To pay for a course, you have the option of 'Per Course' or 'Per Class,' depending on the course. To access the course content, you'll need to top up your wallet with sufficient funds. Your wallet balance will only be deducted when you join classes.</p>
                    </div>

                    <div className="col-sm-12 align-justify">
                        <h5 className="fw-bold">6- How Can I Top Up The Wallet?</h5>
                        <p>To top up your wallet, follow these steps:</p>
                        <ol>
                            <li> Go to the 'My Wallet' tab.</li>
                            <li> Click on 'Top Up.'</li>
                            <li>  Enter the desired top-up amount.</li>
                            <li>  Select your preferred payment method and currency.</li>
                            <li>  Provide the necessary payment details on the checkout page.</li>
                            <li>  Once the payment is completed, your wallet will be successfully topped up and ready for you to join classes.</li>
                        </ol>
                    </div>

                    <div className="col-sm-12 align-justify">
                        <h5 className="fw-bold">7- Do I Get Charged Any Additional Fees?</h5>
                        <p>No, you won't incur any additional fees. The amount you see at the checkout is the exact amount you'll be charged. For instance, if you pay EGP 3150.50, your total charge will be EGP 3150.50.</p>

                        <h5 className="fw-bold">8- Can I Join Multiple Courses?</h5>
                        <p>Yes, you can absolutely join multiple courses. Just make sure you have enough balance in your wallet to cover the fees for all the courses you wish to enroll in. If you're concerned about class timings conflicting with each other, don't hesitate to contact us for assistance in arranging a suitable schedule.</p>

                        <h5 className="fw-bold">9- How Can I Withdraw From A Course?</h5>
                        <p>You can withdraw from any course at any time. Simply reach out to one of our administrators, and we will handle the withdrawal process for you.</p>

                        <h5 className="fw-bold">10- Do I Get Refunded If I Withdraw From Course?</h5>
                        <p>Yes, you can request a refund if you withdraw from a course, but the refund eligibility depends on the timing of your withdrawal and the specific course. Our team will review your request, and if you have any remaining credits, we will process the refund accordingly.</p>
                    </div>

                    <div className="col-sm-12 align-justify">
                         <h5 className="fw-bold">11- How Do I Get To Know Available Courses?</h5>
                        <p>To discover available courses, explore the 'Courses' section on our platform. You can search for courses by system, level, or even by the teacher. If you can't find a specific course, don't hesitate to contact us, and we'll assist you in finding the right one.</p>

                         <h5 className="fw-bold">12- How Can I Find More About The Teacher?</h5>
                        <p>You can learn more about our teachers by visiting the 'Teachers' tab. Here, you can search for specific teachers and discover information about the courses they offer, their experiences, and even read feedback from previous parents and students.</p>

                         <h5 className="fw-bold">13- Does We Teach Provide Any Promotions?</h5>
                        <p>Yes, We Teach does provide promotions periodically through our WhatsApp groups. These promotions can include early bird discounts, discounts for enrolling in multiple courses, and group discounts. To stay updated and learn more about these promotions, be sure to follow our feed and reach out to us for additional details.</p>

                         <h5 className="fw-bold">14- Can I Try Classes Before I Pay?</h5>
                        <p>Yes, we believe it's important for you to have a sense of the teacher's teaching style before committing to a course. Therefore, we offer a live free class before the course officially begins in most subjects. Additionally, we provide recorded sessions for you to review. If you still need more assurance, you can attend 1-2 live classes for a fee before making your decision.</p>

                         <h5 className="fw-bold">15- What If I don't Feel Comfortable With A Teacher?</h5>
                        <p>If you don't feel comfortable with a teacher, don't worry. You can contact us at any point during the course, and we'll assist you. We offer multiple teachers for the same course, so we can help you switch to a different teacher who better suits your preferences and comfort.</p>

                         <h5 className="fw-bold">16- Is It True That I Can Get Full Course @ Price OF A Class?</h5>
                        <p>Yes, it's true! With our new installment system, you can pay for the entire course over up to 8 installments. This makes it as affordable as paying for just one class each month in GCC, making it easier on parents' budgets. This offer is available for students in the UAE, KSA, Kuwait, Qatar, and Bahrain.</p>

                         <h5 className="fw-bold">17- As A Parent How Can I Follow My Student Progress?</h5>
                        <p>As a parent, staying updated on your student's progress is effortless with us. Through your parent account, you'll have a comprehensive view of your student's educational journey. You can track attendance, assignments, quizzes, marks, and overall performance, among other insights. Plus, our instant notification system ensures you're always promptly informed about any updates related to your student's performance in the course. We make it easy for you to actively follow and support your child's educational path.</p>

                         <h5 className="fw-bold">18- When Does Recordings Get Uploaded?</h5>
                        <p>Recordings of classes begin processing as soon as the class starts. Once the class concludes, it takes approximately 20-30 minutes for the recording to be automatically available on the site. In some cases, especially for classes held during peak times, it might take up to 60 minutes. But don't worry, as soon as the recording becomes available, you'll receive an instant notification on your phone.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Faq