import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
// import {fetchData} from "../../components/Helper";

function ClassOver() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // const queryParams = new URLSearchParams(location.search);
        // const zoomClassId = queryParams.get('zoomClassId');
        // const role = queryParams.get('role');

        // if (zoomClassId && (role === "1")) {
        //     fetchData(`over-class/${zoomClassId}`, "post",   {pathname: location.pathname},  true, false,
        //         (res) => {
        //             document.getElementById('topHeader').style.display = 'block';
        //            navigate('/timing/details')
        //         },
        //         (err) => {}
        //     );
        // }
        // else{
        //    navigate('/timing/details')
        // }
        setTimeout(() => {
            navigate('/timing/details')
        }, 400)
    }, [location, location.pathname, location.search, navigate]);

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <section className="d-flex align-items-center align-middle p-5 w-100 start-0 top-0 h-100">
                    <style jsx="true">{`
                        h1{
                            font-size: calc( 12vw + 24px);
                        }
                        h5{
                            font-size: calc( 1vw + 16px);
                        }
                    `}</style>
                    <div className="col-sm-12 text-center">
                        <h5 className="fw-bolder text-orange my-5">Please Wait</h5>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ClassOver;
