import {useEffect, useState} from "react";
import {Link, useSearchParams,useNavigate} from "react-router-dom";
import $ from "jquery";
import {fetchData, intlTel_phone, validateForm, validatePhone, scrollbarSetToTop} from "../components/Helper";
import {Helmet} from "react-helmet";

function SignUp() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [studentData, setStudentData] = useState({country: "ae", type: "student"});
    const [parentData, setParentData] = useState({country: "ae", type: "parent"});
    const [reload, setReload] = useState(0);
    const [phoneInput, setPhoneInput] = useState();

    useEffect(() => {
        if (searchParams.size>0) {
            setStudentData((prevState) => ({
                ...prevState,
                'invite_student_email': searchParams.get("email"),
                'student_email': searchParams.get("email"),
                student_name: searchParams.get("name")
            }));
            setParentData({...parentData, student_email: searchParams.get("email"),invite_student_email: searchParams.get("email"),student_name:searchParams.get("name")});
        }
        scrollbarSetToTop();
        setPhoneInput({
            student: intlTel_phone("#student_phone", "#country", "ae"),
            parent: intlTel_phone("#parent_phone", "#parent_country", "ae"),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);
    const handleInputChange = (e) => {
        let id = document.getElementById(e.target.name);
        if (id) {
            id.classList.remove("is-invalid");
            $(e.target.name).siblings(".invalid-feedback").css("display", "none");
        }
        $("li.third-item").next().css("background-color", "red");
        setStudentData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        setParentData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleSaveStudent = (e) => {
        e.preventDefault();
        if (validateForm(e) && validatePhone(phoneInput.student, "#student_phone")) {
            let student_data = {...studentData, student_phone: phoneInput.student.getNumber()};
            fetchData(
                "student-parent-register",
                "POST",
                student_data,
                false,
                false,
                (res) => {
                    if (res.status) {
                        //document.getElementById('parentForm').classList.add('needs-validation');
                        $(".nav-pills .active")
                        .parent()
                        .parent()
                        .find(".nav-item .nav-link:not(.active)")
                        .trigger("click");
                    } else {
                        $(".nav-pills .active")
                        .parent()
                        .parent()
                        .find(".nav-item .nav-link:not(.active)")
                        .dispatch("click");
                    }
                },
                (err) => {}
            );
        }
    };

    const handleSaveParent = (e) => {
        e.preventDefault();
        var reset_std = document.getElementById("studentForm");
        var reset_par = document.getElementById("parentForm");
        if (validateForm(e) && validatePhone(phoneInput.parent, "#parent_phone")) {
            let parent_data = {
                ...parentData,
                student_phone: phoneInput.student.getNumber(),
                parent_phone: phoneInput.parent.getNumber(),
            };
            if (!parent_data.parent_country) {
                parent_data = {...parent_data, parent_country: "ae"};
            }
            fetchData(
                "student-parent-register",
                "POST",
                parent_data,
                false,
                false,
                (res) => {
                    if (res.status) {
                        setParentData({parent_country: "ae", type: "parent"});
                        setStudentData({country: "ae", type: "student"});
                        reset_std.reset();
                        reset_par.reset();
                        reset_par.classList.remove("needs-validation", "was-validated");
                        reset_std.classList.remove("was-validated");
                        phoneInput.student.destroy();
                        phoneInput.parent.destroy();
                        $("#pillsparent").removeClass("show active");
                        $("#pillsstudent").addClass("show active");
                        $("#parentTab").removeClass("active");
                        $("#studentTab").addClass("active");
                        setReload(reload + 1);
                        navigate('/login', { replace: true });
                    }
                },
                (err) => {}
            );
        }
    };

    const showPassword = (e, getid) => {
        let bt = e.target;
        let ps = document.getElementById(getid);
        if (ps.getAttribute("type") === "password") {
            ps.setAttribute("type", "text");
            bt.innerHTML = '<i className="bi-eye-slash fs-5 pe-none"></i>';
        } else {
            ps.setAttribute("type", "password");
            bt.innerHTML = '<i className="bi-eye fs-5 pe-none"></i>';
        }
    };

    const gotoParentForm = (e, tabid) => {
        e.preventDefault();
        if (validateForm(e, "studentForm") && validatePhone(phoneInput.student, "#student_phone")) {
            let student_data = {...studentData, student_phone: phoneInput.student.getNumber()};
            fetchData(
                "student-parent-register",
                "POST",
                student_data,
                false,
                false,
                (res) => {
                    if (res.status) {
                        $("#pillstab .nav-link").removeClass("active");
                        $(e.target).addClass("active");

                        $("#pillstabContent .tab-pane").removeClass("show active");
                        $(tabid).addClass("show active");
                    } else {
                    }
                },
                (err) => {}
            );
        }
    };

    return (
        <section className="login-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Sign Up</title>
            </Helmet>
            <div className="container">
                <div className="row g-0 rounded-10 shadow overflow-hidden">
                    <div className="col-md-12 col-lg-7 bg-white">
                        <div className="p-4 p-sm-5" style={{minHeight: "556px"}}>
                            <div className="row justify-content-center">
                                <div className="col-sm-12 mb-4">
                                    <h4 className="heading-lines mb-0">Create Account</h4>
                                </div>
                            </div>
                            <ul className="nav nav-pills nav-common-pills mb-4" id="pillstab" role="tablist">
                                <li className="nav-item mb-3" role="presentation">
                                    <button
                                        className="nav-link active"
                                        type="button"
                                        id="studentTab"
                                        onClick={(e) => gotoParentForm(e, "#pillsstudent")}
                                        title="Student's Details"
                                    >
                                        Student's Details
                                    </button>
                                </li>
                                <li className="nav-item mb" role="presentation">
                                    <button
                                        className="nav-link"
                                        type="button"
                                        id="parentTab"
                                        title="Parent's Details"
                                        onClick={(e) => gotoParentForm(e, "#pillsparent")}
                                    >
                                        Parent's Details
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pillstabContent">
                                <div className="tab-pane fade show active" id="pillsstudent">
                                    <form className="needs-validation" id="studentForm" noValidate>
                                        <div className="row g-4 justify-content-center" id="step1">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={
                                                            searchParams.get("name")
                                                                ? searchParams.get("name")
                                                                : ""
                                                        }
                                                        // pattern="[a-zA-Z]+ [a-zA-Z]+$"
                                                        pattern="([a-zA-Z]+ [a-zA-Z]+( [a-zA-Z]+)*)+"
                                                        name="student_name"
                                                        id="student_name"
                                                        onChange={handleInputChange}
                                                        placeholder="Student Name*"
                                                        required
                                                    />
                                                    <small className="text-danger validation-font ">
                                                        <b>Note: </b> First Name & Last Name is required
                                                    </small>
                                                    <label htmlFor="studentname">Student Name*</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        readOnly={searchParams.get("email") ? true : false}
                                                        defaultValue={
                                                            searchParams.get("email")
                                                                ? searchParams.get("email")
                                                                : ""
                                                        }
                                                        name="student_email"
                                                        id="student_email"
                                                        placeholder="Student Email"
                                                        onChange={handleInputChange}
                                                        required={searchParams.get("email") ? false : true}
                                                    />
                                                    <label htmlFor="studentemail">Student Email*</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating">
                                                    <select
                                                        className="form-select"
                                                        name="country"
                                                        id="country"
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        {" "}
                                                    </select>
                                                    <label htmlFor="countrytext">Country</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="phone"
                                                        className="form-control"
                                                        name="student_phone"
                                                        id="student_phone"
                                                        autoComplete="off"
                                                        data-intl-tel-input-id="0"
                                                        style={{height: "58px"}}
                                                        title="WhatsApp Number"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating mb-31 position-relative">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="student_password"
                                                        id="student_password"
                                                        placeholder="Password"
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <label htmlFor="studentpassword">Password*</label>
                                                    <button
                                                        type="button"
                                                        onClick={(e) => showPassword(e, "student_password")}
                                                        className="btn position-absolute px-1 me-4 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none"
                                                    >
                                                        <i className="bi-eye fs-5 pe-none"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating mb-31 position-relative">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="student_confirm_password"
                                                        id="student_confirm_password"
                                                        placeholder="Confirm Password"
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <label htmlFor="studentcpassword">Confirm Password*</label>
                                                    <button
                                                        type="button"
                                                        onClick={(e) => showPassword(e, "student_confirm_password")}
                                                        className="btn position-absolute px-1 me-4 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none"
                                                    >
                                                        <i className="bi-eye fs-5 pe-none"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <button
                                                    type="button"
                                                    className="btn btn-blue btn-lg w-100 btn-next"
                                                    onClick={handleSaveStudent}
                                                    title="NEXT STEP"
                                                >
                                                    NEXT STEP &#10140;
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="tab-pane fade" id="pillsparent">
                                    <form id="parentForm" noValidate>
                                        <div className="row g-4 justify-content-center">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="parent_name"
                                                        id="parent_name"
                                                        onChange={handleInputChange}
                                                        placeholder="Parent Name*"
                                                        required
                                                    />
                                                    <label htmlFor="parentname">Parent Name*</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="parent_email"
                                                        id="parent_email"
                                                        onChange={handleInputChange}
                                                        placeholder="Parent Email"
                                                        required
                                                    />
                                                    <label htmlFor="parentemail">Parent Email*</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating">
                                                    <select
                                                        className="form-select"
                                                        name="parent_country"
                                                        id="parent_country"
                                                        onChange={handleInputChange}
                                                        required
                                                    ></select>
                                                    <label htmlFor="countrytext">Country</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="tel"
                                                        className="form-control"
                                                        id="parent_phone"
                                                        autoComplete="off"
                                                        name="parent_phone"
                                                        data-intl-tel-input-id="0"
                                                        style={{height: "58px"}}
                                                        data-bs-toggle="tooltip"
                                                        title="WhatsApp Number"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating mb-31 position-relative">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="parent_password"
                                                        id="parent_password"
                                                        placeholder="Parent Password"
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <label htmlFor="parentpassword">Password*</label>
                                                    <button
                                                        type="button"
                                                        onClick={(e) => showPassword(e, "parent_password")}
                                                        className="btn position-absolute px-1 me-4 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none"
                                                    >
                                                        <i className="bi-eye fs-5 pe-none"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-floating mb-31 position-relative">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        name="parent_confirm_password"
                                                        id="parent_confirm_password"
                                                        placeholder="Parent Confirm Password"
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    <label htmlFor="parentcpassword">Confirm Password*</label>
                                                    <button
                                                        type="button"
                                                        onClick={(e) => showPassword(e, "parent_confirm_password")}
                                                        className="btn position-absolute px-1 me-4 top-0 end-0 translate-middle-y mt-4 pt-3 shadow-none"
                                                    >
                                                        <i className="bi-eye fs-5 pe-none"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <button
                                                    type="button"
                                                    className="btn btn-blue btn-lg w-100"
                                                    onClick={handleSaveParent}
                                                    title="SIGN UP"
                                                >
                                                    SIGN UP
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                                <Link
                                    to="/login"
                                    className="anchor-blueorange fw-medium"
                                    title="Already have an account?"
                                >
                                    Already have an account?
                                </Link>
                            </div>
                            <div className="d-flex justify-content-between">
                                <a
                                    href="https://youtu.be/o92rxQOQDrE"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="anchor-blueorange fw-medium"
                                    title="How to sign up?"
                                >
                                    How to sign up?
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-5">
                        <img src="images/sign-up.svg" className="w-100" alt="weteach" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SignUp;
