import React from "react";
import { useEffect } from "react";
import parse from 'url-parse';

const YoutubeEmbed = (props) => {

  function getYouTubeEmbedId(url) {
    const parsedUrl = parse(url, true);
    const hostname = parsedUrl.hostname;
    let videoId = '';
  
    if (hostname === 'www.youtube.com' || hostname === 'youtube.com') {
      if (parsedUrl.query.v) {
        videoId = parsedUrl.query.v;
      } else if (parsedUrl.pathname) {
        const match = parsedUrl.pathname.match(/\/(embed|v|vi|watch)\?v?=?([^#\&\?]*).*/);
        if (match && match[2]) {
          videoId = match[2];
        }
      }
    }
  
    return videoId;
  }

  const videoId = getYouTubeEmbedId(props && props.embedId);

  var videoUrl = `https://www.youtube.com/embed/${videoId}`
  var frameId = `#${props.modalId} #ytplayer`

  useEffect(() => {
    let videoModal = document.getElementById(props && props.modalId)

    if (videoModal) {
        videoModal.addEventListener('hidden.bs.modal', function () {
            document.querySelector(frameId).setAttribute('src', '');
        })

        videoModal.addEventListener('show.bs.modal', function () {
            document.querySelector(frameId).setAttribute('src', videoUrl);
        })
    }

}, [props, frameId, videoUrl])

  return (
  <>
    <div className="modal fade" id={ props && props.modalId } tabIndex="-1" data-bs-backdrop="static" aria-modal="true" role="dialog">
      <div className="modal-dialog modal-dialog-scrollable modal-lg">
        <div className="modal-content border-0 shadow">
          <div className="modal-header bg-light">
            <h5 className="modal-title">Help Video</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='Close'></button>
          </div>
          <div className="modal-body p-sm-4">
            <div className="video-responsive">
              <iframe
                width="100%"
                height="100%"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                id='ytplayer'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
};

export default YoutubeEmbed;