import React from 'react'
import _ from 'lodash';

function MaterilDetailsViewModal({ materialType, children }) {

    return (
        <div className="modal fade stmub" id="viewModal" tabIndex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-xl modal-width">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {_.capitalize(materialType)} View
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive">
                            <table className="table align-middle table-lasttd-end mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th className="border-bottom">#ID</th>
                                        <th className="border-bottom">Name</th>
                                        { materialType === "performance" && <>  <th> Mark</th> </> }
                                        <th className="border-bottom">Email</th>
                                        {(materialType === "quizz" || materialType === "assignment") && <>
                                            <th className="border-bottom">Status</th>
                                            <th className="border-bottom">Submission</th>
                                            <th className="border-bottom">Action</th>
                                        </>}
                                        {materialType === "performance" && <th className="border-bottom">Status</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {children}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaterilDetailsViewModal
