import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {useEffect, useState} from "react";
import {fetchData, scrollbarSetToTop} from "../components/Helper";
import {Helmet} from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import {Modal, Button} from "react-bootstrap";

function TimeTable() {
    const [show, setShow] = useState(false);
    const [modalData, setmodalData] = useState({
        typename: "",
        titlename: "",
        description: "",
        timing: "",
    });
    const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);
    const [events, setEvents] = useState();
    useEffect(() => {
        scrollbarSetToTop();
        fetchData(
            "get-timetable",
            "GET",
            "",
            false,
            false,
            (res) => {
                if (res.status && res.data) {
                    setEvents(res.data);
                }
            },
            (err) => {}
        );
    }, []);

    const opendetailModal = (getid) => {
         setShow(true);
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Time Table</title>
            </Helmet>
            <section className="bg-blue py-4 position-relative overflow-hidden">
                <img
                    src="images/courses-bg.png"
                    className="position-absolute top-0 opacity-25 zindex-0 pointer-none"
                    alt="weteach"
                />
                <div className="container position-relative zindex-2">
                    <div className="row align-items-center">
                        <div className="col">
                            <h2 className="heading-lines text-white m-0 pt-3">Time Table</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-3">
                <div className="container">
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12 mb-5">
                            <div id="calendar"></div>
                            {events ? (
                                <FullCalendar
                                    buttonText={{
                                        today: "Today",
                                        month: "Month",
                                        week: "Week",
                                        day: "Day",
                                        list: "List",
                                    }}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                    headerToolbar={{
                                        left: "prev,next today",
                                        center: "title",
                                        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                    }}
                                    initialView="timeGridDay"
                                    dayMaxEvents={true}
                                    eventClick={(arg) => {
                                        let type = arg.event.extendedProps.course_id === 0 ? "Event" : "Course";
                                        opendetailModal(arg.event.extendedProps.course_id, );
                                        setmodalData({
                                            typename: type,
                                            titlename: arg.event.title,
                                            description: arg.event.extendedProps.description,
                                            timing: arg.event.extendedProps.event_timing,
                                        });
                                    }}
                                    initialEvents={events}
                                    slotMinTime="00:00:00"
                                    slotMaxTime="24:00:00"
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {/* Start | Course Details Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {modalData.typename} Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-info rounded mb-2">
                        <div className="fs-13px text-uppercase mb-1">
                            <span className="set_typename">{modalData.typename}</span> Title
                        </div>
                        <div className="text-dark fw-medium set_titlename">{modalData.titlename}</div>
                    </div>
                    <div className="alert alert-success rounded mb-2">
                        <div className="fs-13px text-uppercase mb-1">
                            <span className="set_typename">{modalData.typename}</span> Description
                        </div>
                        <div className="text-dark fw-medium set_description">{modalData.description}</div>
                    </div>
                    <div className="alert alert-warning rounded mb-2">
                        <div className="fs-13px text-uppercase mb-1">
                            <span className="set_typename">{modalData.typename}</span> Date Time
                        </div>
                        <div className="text-dark fw-medium set_coursetiming">{modalData.timing}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* End | Course Details Modal */}
        </>
    );
}

export default TimeTable;
