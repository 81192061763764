import { createRoot } from "react-dom/client";
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import Datatables, { reloadUrlDataTable } from '../../components/Datatables';
import { fetchData, validateForm, ModalSection, initialFormState } from '../../components/Helper';

function  AutoMessage(props) {
    const listType = useParams().list_type
    const [previousModalId, setPreviousModalId] = useState(0)
    const [autoMessage, setAutoMessage] = useState({title: '', message: '', arabic_message: '', comment: ''})
    const [editAction, setEditAction] = useState(false)
    const [auto_message_dt] = useState({
        dt_url: `auto-message-list`,
        dt_name: 'auto_message_table',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"template_for", name:"template_for", title:"Template For", class:"text-nowrap"},
            { data:"title", name:"title", title:"Title"},
            { data:"message", name:"message", title:"English Message"},
            { data:"arabic_message", name:"arabic_message", title:"Arabic Message", width:400},
            { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-center text-nowrap", width:160}
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        {rowData.template_for === 1 && <span>Super Admin</span>}
                        {rowData.template_for === 2 && <span>Admin</span>}
                        {rowData.template_for === 3 && <span>Assistant</span>}
                        {rowData.template_for === 4 && <span>Teacher</span>}
                        {rowData.template_for === 5 && <span>Parent</span>}
                        {rowData.template_for === 6 && <span>Student</span>}
                    </>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <span dir='rtl'>{rowData.arabic_message}</span>
                    </>)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <button type="button" className="btn btn-primary py-2px m-2px" title="View" data-bs-target="#updateTemplate" data-bs-toggle="modal" onClick={() => getTemplate(rowData.id)}>
                            View
                        </button>
                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" data-bs-target="#updateTemplate" data-bs-toggle="modal" onClick={() => getTemplate(rowData.id, true)}>
                            Edit
                        </button>
                    </>)
                }
            },
        ]
    })


    useEffect(() => {
        if(listType === 'auto-message'){
            reloadUrlDataTable(auto_message_dt, `auto-message-list`)
        }
    }, [listType, auto_message_dt])

    const handleInputChange = (e, type) => {
        if(type === 'auto-message') {
            setAutoMessage(prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        }
        
    }

    const getTemplate = (id, method = false) => {
        setPreviousModalId(id)
        setEditAction(method)
        initialFormState("templateForm", {})
        fetchData(`edit-auto-message/${id}`, 'GET', '', true, false, (res) => { 
            if(res.status){
                setAutoMessage({
                    'title': res.data.title,
                    'message': res.data.message,
                    'arabic_message': res.data.arabic_message,
                    'comment': res.data.comment,
                })
            }
        }, (err) => {})
    }

    const updateTemplate = (e) => {
        e.preventDefault();
        if(validateForm(e, "templateForm")){ 
            fetchData(`edit-auto-message/${previousModalId}`, 'POST', autoMessage , true, false, (res) => {
                if(res.status){
                    reloadUrlDataTable(auto_message_dt, `auto-message-list`)
                    document.querySelector("#updateTemplate [data-bs-dismiss=modal]").click()
                }
            }, (err) => {})
        }
    }

    return(
        <>
            <div className="table-responsive p-1">
                <Datatables dt_name="auto_message_table"/>
            </div>


            <ModalSection title="Template" modal_id="updateTemplate" size="lg">
                <form action="#" method="post" className="needs-validation" id="templateForm" noValidate>
                    <div className="row mx-0 mb-4">
                        <label htmlFor="title" className="col-sm-4 col-form-label">Title<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-8 col-xxl-7">
                            <input type="text" className="form-control" id="title" name="title" defaultValue={autoMessage.title}  onChange={(e) => handleInputChange(e, 'auto-message')} readOnly={!editAction} required />
                        </div>
                    </div>
                    <div className="row mx-0 mb-4">
                        <label htmlFor="message" className="col-sm-4 col-form-label">English Message<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-8 col-xxl-7">
                            <textarea className="form-control" id="message" name="message" defaultValue={autoMessage.message} onChange={(e) => handleInputChange(e, 'auto-message')} readOnly={!editAction} required  rows={5} /> 
                        </div>
                    </div>
                    <div className="row mx-0 mb-4">
                        <label htmlFor="arabic_message" className="col-sm-4 col-form-label">Arabic Message<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-8 col-xxl-7">
                            <textarea className="form-control" id="arabic_message" name="arabic_message" dir='rtl' defaultValue={autoMessage.arabic_message} onChange={(e) => handleInputChange(e, 'auto-message')} readOnly={!editAction} required  rows={5} /> 
                        {/* <p className='text-muted'>{autoMessage.comment}</p> */}
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-sm-8 offset-sm-4">
                            { editAction ? <button type="submit" className="btn btn-blue w-120px px-4 me-2" onClick={updateTemplate}>Update</button>
                            : <button type="button" className="btn btn-dark w-120px px-4 me-2" onClick={(e) => {e.preventDefault();setEditAction(true)}}>Edit</button> }
                        </div>
                    </div>
                </form>
            </ModalSection>
        </>
    )
}

export default AutoMessage
