import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import Context from '../components/Context'
import { fetchData,scrollbarSetToTop, validateForm } from '../components/Helper'

function Login(){
    const navigate = useNavigate()
    const [context, setContext] = useContext(Context)
    const [credentials, setCredentials] = useState({email: "", password: ""})
    const [token] = useState(localStorage.getItem('wt-token'))

    useEffect(() => {
        scrollbarSetToTop();
        if(token){
            if(context && context.auth){
                if(context.auth.role_id === 1 || context.auth.role_id === 2){
                    navigate('/dashboard')
                }else{
                    navigate('/my-profile')
                }
            }
        }
    }, [token, context,  navigate])

    const handleInputChange = (e) => {
        if(e.target.name === 'password'){
            document.getElementById("floatingPassword").classList.remove('is-invalid');
        }
        setCredentials(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const submitLoginForm = (e) => {
        e.preventDefault();
        if(validateForm(e)){
            let password_length = document.getElementById("floatingPassword")
            if(password_length.value.length < 8) {
                password_length.classList.add('is-invalid'); 
            }else{
                fetchData('login', 'POST', credentials, false, false, (res) => {
                    if(res.status && res.data){
                        localStorage.setItem("wt-token", res.data.token);
                        setContext(prevState => ({
                            ...prevState,
                            auth: res.data
                        }))
                        if(res.data.role_id === 1 || res.data.role_id === 2){
                            navigate('/dashboard')
                        }else{
                             navigate('/my-profile')
                        }
                    }
                }, (err) => {})
            }
        }
    }

    return(
        <section className="login-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Login</title>
            </Helmet>
            <div className="container">
                <div className="row g-0 rounded-10 shadow overflow-hidden">
                    <div className="col-md-6 col-lg-5 bg-white d-flex align-items-center">
                        <form action="#" method="post" className="p-4 p-sm-5 w-100 needs-validation" noValidate>
                            <h4 className="heading-lines mb-4 mt-0">Login</h4>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="floatingEmail" name="email" onChange={handleInputChange} placeholder="name@example.com" required />
                                <label htmlFor="floatingEmail">Email address*</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="floatingPassword" name="password" onChange={handleInputChange} placeholder="*********" required />
                                <label htmlFor="floatingPassword">Password*</label>
                                <em className='small text-success'>Please enter minimum 8 characters.</em>
                            </div>
                            <div className="mb-4">
                                <button type="submit" className="btn btn-blue btn-lg w-100" onClick={submitLoginForm} title='LOGIN'>LOGIN</button>
                            </div>
                            <div className="d-flex justify-content-between pb-3">
                                <Link to="/forgot-password" className="anchor-blueorange fw-medium" title='Forgot Password?'>Forgot Password?</Link>
                                <Link to="/sign-up" className="anchor-blueorange fw-medium" title='Sign Up'>Sign Up</Link>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 col-lg-7">
                        <img src="images/login.svg" className="w-100" alt="weteach"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login
