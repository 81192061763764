import React, { Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import Context from "../../components/Context";
import { useEffect, useState, useContext } from "react";
import { fetchData, showAlert, decryptCode } from "../../components/Helper";
import Pagination from "../../components/Pagination";
import _, { isEmpty } from "lodash";
import { Modal, Button } from "react-bootstrap";
import MaterilDetailsBoby from "./components/MaterilDetailsBoby";
const ZoomComponent = lazy(() => import('./components/Zoom'));


function ClassRecordingDetails(props) {
    const [tinyloader, setTinyloader] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const location = useLocation();
    const userId = props.student_id;
    const courseId = props.course_id;
    const materialType = props.material;
    const [context] = useContext(Context);
    const roleId = context.auth.role_id;
    const userAgent = navigator.userAgent;

    const [materialList, setMaterialList] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [reload, setReload] = useState(1);
    const [paymentData, setPaymentData] = useState();
    const [zoomData, setZoomData] = useState({});
    const [launchId, setLaunchId] = useState(0);
    const [zoomLaunch, setZoomLaunch] = useState(false);

    useEffect(() => {
        if (materialType === 'timing' && zoomLaunch) {
            window.history.pushState(null, null, location.href);
            window.window.onpopstate = function(event) {
                if (event.type === 'popstate') {
                    if (window.confirm("Do you want to go back ?")) {
                        window.location.reload();
                    }
                }
            };
        }
    }, [location.href, materialType, zoomLaunch]);

    useEffect(() => {
        if (context && context.auth) {
            const reloadAfterSomeTime = (loader = false) => {
                let api_url = `subject-type-list?id=${courseId}&type=${materialType}&user_id=${userId}&status=active&page=${currentPage}`;
                fetchData(api_url, "GET", "", true, false, (res) => {
                    setMaterialList(res.data);
                    if (loader) document.getElementById("group-title").innerHTML = res.data && res.data[1] ? res.data[1] : "";
                }, (err) => { }, false, loader);
            };

            reloadAfterSomeTime(true)
            if (materialType === 'timing') {
                var interval = setInterval(reloadAfterSomeTime, 10000);
            }
        }
        return () => materialType === 'timing' ? clearInterval(interval) : null;
    }, [context, currentPage, reload, courseId, materialType, userId, location.pathname]);

    const handlePageClick = (data) => setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0);

    const launchClassOrRecording = (record, type = false) => {
        try {
            fetchData(`launch-class-or-recording/${record.class_id}`, "post", { user_id: userId, type: type, pathname: location.pathname }, true, false, (res) => {
                if (res.status && res.data === "") {
                    setReload(reload + 1);
                    if (type) {
                        if (navigator.userAgent.includes('iPad') && navigator.userAgent.includes('Safari')) {
                            window.location.assign(record.recording);
                          } else {
                            window.open(record.recording);
                          }                          
                    } else {
                        if (record.type === "zoom") {
                            setTinyloader(true)
                            setZoomData({
                                role: roleId === 3 || roleId === 4 ? 1 : 0,
                                meeting_id: decryptCode(record.meeting_id),
                                password: decryptCode(record.meeting_password),
                                api_key: record.api_key,
                                api_secret: record.api_secret,
                                username: context.auth.name,
                                useremail: context.auth.email,
                            })
                            setLaunchId(record.class_id)
                            setZoomLaunch(true)
                            document.querySelector("#student_sidebar").style.display = "none";
                        } else {
                            launchWebex(record.class_id);
                        }
                    }
                } else if (res.status) {
                    setPaymentData({
                        join_type: res.data.join_type,
                        pay_amount: res.data.pay_amount,
                        type: res.data.type,
                        meeting_id: record.meeting_id,
                        password: record.meeting_password,
                        api_key: record.api_key,
                        api_secret: record.api_secret,
                        class_id: res.data.class_id,
                        recording: record.recording,
                        webex: record.type === 'webex' ? true : false,
                    });
                    setShow(true);
                } else {
                    setReload(reload + 1);
                }
            },
                (err) => { }
            );
        } catch (error) {
            console.error(error); // You might send an exception to your error tracker like AppSignal
            return error;
        }
    };

    const doPayment = () => {
        let allData = { ...paymentData, user_id: userId, course_id: courseId, pathname: location.pathname };
        fetchData(`course-or-class-payment`, "post", allData, true, false, (res) => {
            if (res.status) {
                setReload(reload + 1);
                if (paymentData.type) {
                    setTimeout(() => {
                        window.open(paymentData.recording);
                    }, 1500);
                } else {
                    setTimeout(() => {
                        if (paymentData.webex) {
                            launchWebex(paymentData.class_id);
                        } else {
                            setTinyloader(true)
                            setZoomData({
                                role: roleId === 3 || roleId === 4 ? 1 : 0,
                                meeting_id: decryptCode(paymentData.meeting_id),
                                password: decryptCode(paymentData.password),
                                api_key: paymentData.api_key,
                                api_secret: paymentData.api_secret,
                                username: context.auth.name,
                                useremail: context.auth.email,
                            })
                            setLaunchId(paymentData.class_id)
                            setZoomLaunch(true)
                        }
                    }, 1500);
                }
            }
        }, (err) => { });
        setShow(false);
    };


    const launchClassByZoomLink = (record) => {
        fetchData(`launch-class-or-recording/${record.class_id}`, "post", { user_id: userId, pathname: location.pathname }, true, false, (res) => {
            if (res.status && res.data === "") {
                if (record.type === 'zoom') {
                    setTinyloader(true)
                    setZoomData({
                        role: roleId === 4 || roleId === 3 ? 1 : 0,
                        meeting_id: decryptCode(record.meeting_id),
                        password: decryptCode(record.meeting_password),
                        api_key: record.api_key,
                        api_secret: record.api_secret,
                        username: context.auth.name,
                        useremail: context.auth.email,
                        class_id: record.class_id,
                    })
                    setLaunchId(record.class_id)
                    setZoomLaunch(true)
                }
                else {
                    launchWebex(record.class_id);
                }
            } else {
                setReload(reload + 1);
            }
        }, (err) => { });
    };

    const launchWebex = (class_id) => {
        setTinyloader(true);
        fetchData(`get-webex-meeting-link/${class_id}`, "GET", '', true, true, (res) => {
            setTinyloader(false);
            if (res.status && res.data) {
                let join_url = res.data.joinLink;
                if ((roleId === 3 || roleId === 4) && res.data.startLink) {
                    join_url = res.data.startLink;
                }
                fetchData(`class-launch-successfull/${class_id}`, "GET", "", true, false, (res) => { }, (err) => { });
                window.open(join_url, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no');
            } else {
                let msg = { message: "OOps! Something went wrong.", status: false };
                showAlert(msg);
            }
        }, (err) => { });
    }

    const overClass = (class_id) => {
        fetchData(`over-class/${class_id}`, "post", { pathname: location.pathname }, true, false, (res) => {
            setReload(prev => prev + 1)
        }, (err) => { });
    }
    return (
        <>
            <MaterilDetailsBoby roleId={roleId} materialType={materialType} setCurrentPage={setCurrentPage} pagination={<Pagination paginate_data={materialList ? materialList[0] : ""} side="end" onPageChange={handlePageClick} />}>
                {materialList && materialList[0].data && !isEmpty(materialList[0].data) ? _.toArray(materialList[0].data).map((record, key) => {
                    return (
                        <tr key={key}>
                            {materialType === "recording" && <>
                                <td>{record.status === "in-progress" ? record.class_date : record.date}</td>
                                <td>{record.name}</td>
                                <td>{record.status === "in-progress" ? "-" : record.attended}</td>
                                <td>{record.status === "in-progress" ? <span className="text-warning btn-sm minw-120px">In Progress</span> : <span className="text-success btn-sm minw-120px">Active</span>}</td>
                                <td>
                                    {record.status !== "in-progress" && <>
                                        <button className={`btn btn-${record.course_payment || record.class_payment ? "blue" : "orange"} btn-sm px-4 minw-120px launch-btn`} disabled={roleId === 5 ? true : false} onClick={(e) => launchClassOrRecording(record, "recording")} title={record.course_payment || record.class_payment ? "Watch" : "Purchase"} data-key={key}>
                                            <span className="spinner-border spinner-border-sm mr-1 hidden"></span>
                                            {record.course_payment || record.class_payment ? "Watch" : "Purchase"}
                                        </button>
                                    </>}
                                </td>
                            </>}
                            {materialType === "timing" && <>
                                <td>{record.day} </td>
                                <td>{record.start_class_time} - {record.end_class_time}</td>
                                <td className="text-center">
                                    {(roleId === 3 || roleId === 4) ? <>
                                        <button className="btn btn-blue btn-sm px-4 minw-120px m-1" disabled={record.status === "active" ? false : true} onClick={() => launchClassByZoomLink(record)} title="Get Class Link">
                                            Get Class Link
                                        </button>
                                        {record.type === "webex" && <>
                                            <button className="btn btn-danger btn-sm px-4 minw-120px m-1 ms-1" disabled={record.status === "active" ? false : true} onClick={() => overClass(record.class_id)} title="Over Class">
                                                Over Class
                                            </button>
                                        </>}
                                    </> : record.type === "webex" ? <>
                                        <button className="btn btn-blue btn-sm px-4 minw-120px m-1 launch-btn" data-key={key}
                                            disabled={ (record.status === "active" &&  roleId === 6)  ? false : true}
                                            onClick={(e) => launchClassOrRecording(record)} title="Launch">
                                            {tinyloader && <span className="spinner-border spinner-border-sm mr-1 hidden"></span>} Launch
                                        </button>
                                    </> : <>
                                        <button className="btn btn-blue btn-sm px-4 minw-120px m-1" disabled={(record.status === "active" && roleId === 6) ? false : true} onClick={() => launchClassOrRecording(record)} title="Launch">
                                        {tinyloader && <span className="spinner-border spinner-border-sm mr-1 hidden"></span>} Launch 
                                        </button>
                                    </>}
                                </td>
                            </>}
                        </tr>
                    );
                }) : <tr><td colSpan={5} className="text-danger text-center">No data available</td></tr>}
            </MaterilDetailsBoby>

            {/** Payment Mosal */}
            {
                paymentData && <>
                    <Modal show={show} onHide={handleClose} className="center_modal" backdrop="static">
                        <Modal.Body className="text-center">
                            <p className="text-muted px-4 py-3 lh-base fs-6 fs-17px mb-1">
                                Dear Student! <strong> EGP {paymentData.pay_amount} </strong> will be debited from your
                                wallet. Do you want to{" "}
                                <strong>{paymentData.type === "recording" ? "purchase" : "attend"}</strong> the{" "}
                                {paymentData.type === "recording" ? "recording?" : "class?"}{" "}
                            </p>
                            <Button variant="transparent" title="No" className="btn btn-outline-orange me-2 minw-100px" onClick={handleClose}>
                                No
                            </Button>
                            <Button variant="transparent" title="Yes" className="btn btn-blue me-2 minw-100px" onClick={doPayment}>
                                Yes
                            </Button>
                        </Modal.Body>
                    </Modal>
                </>
            }

            {zoomLaunch && <Suspense fallback=""><ZoomComponent enableZoom={zoomLaunch} zoomData={zoomData} launchId={launchId} /></Suspense>}
        </>
    );
}

export default ClassRecordingDetails;
