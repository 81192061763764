import { createRoot } from "react-dom/client";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import _ from 'lodash';
import Datatables, { destroyDataTable, initDataTable, redrawDataTable, reloadDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { fetchData } from '../../components/Helper';
import { Helmet } from 'react-helmet';
import { Button, Modal } from 'react-bootstrap';


let profilePath = '';

function AccountList(){
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const accountType = location.pathname.split("/")[2]
    const listType = useParams().list_type
    const [modules, setModules] = useState()
    const [module_ids, setModule_ids] = useState([])

    const [dt] = useState({
        dt_url: `account-list?type=${accountType}&status_q=${listType ? listType : ''}`,
        dt_name: 'account_list',
        dt_order: [[accountType === 'teacher' ? 1 : 0, 'desc']],
        dt_rowReorder: {
            url: 'teacher-change-order',
        },
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data: "order_id", name: 'order_id', title: "#Order", visible: false },
            { data:"name", name:'name', title:"Name"},
            { data:"email", name:'email', title:"Email"},
            { data:"user_details.phone", name:'userDetails.phone', title:"Phone No.", class:"text-nowrap"},
            { data:"status", name:'status',  title:"Status" },
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center noExport", width:160}
        ],
        dt_column_defs: [
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(rowData.user_details && rowData.user_details.phone ? `+${rowData.user_details.phone }` : '-')
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                    { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                    { rowData.status === "pending" ? <span className="text-warning fw-medium">Pending</span> : '' }
                    { rowData.status === "draft" ? <span className="text-warning fw-medium">Draft</span> : '' }
                    </>)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        { rowData.status === 'inactive' || rowData.status === 'pending' ? 
                        <button type="button" className="btn btn-success py-2px m-2px w-100px" title="Activate" onClick={ () => handleChangeStatus('active', rowData.id)}>
                                Activate
                        </button>
                        : '' }

                        { rowData.status === 'active' ?
                        <button type="button" className="btn btn-danger py-2px m-2px w-100px" title="Deactivate" onClick={ () => handleChangeStatus('inactive', rowData.id)}>
                            Deactivate
                        </button>
                        : '' }

                        { rowData.role_id === 2 && rowData.status !== 'draft' ?
                         <Button variant="primary" className="btn btn-primary py-2px m-2px" onClick={() => handlePermission(rowData.id)}>
                         Permission
                       </Button>
                        : '' }

                        { rowData.role_id !== 2 ?
                        <button type="button" className="btn btn-primary py-2px m-2px" title="View Profile" onClick={() => handleViewProfile(rowData.id)}>
                            View Profile
                        </button>
                        : '' }

                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" onClick={() => handleEditProfile(rowData.id)}>
                            Edit
                        </button>

                        { rowData.role_id === 4 ?
                        <button className="btn btn-outline-blue py-2px m-2px action-btn drag">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="3 4 8 8">
                                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                            </svg>
                        </button>
                        : '' }

                        { rowData.status === 'draft' ?
                        <button type="button" className="btn btn-danger py-2px m-2px w-100px" title="Delete" onClick={ () => handleChangeStatus('delete', rowData.id)}>
                            Delete
                        </button>
                        : '' }
                                                    
                    </>)
                },
            },
        ]
    })

    useEffect(() => {
      
        profilePath = accountType
        let status_q = listType ? `&status_q=${listType}` : ''
        destroyDataTable(dt)
        initDataTable(dt)
        reloadUrlDataTable(dt, `account-list?type=${accountType}${status_q}`)
    }, [dt, accountType, listType])

    const handleChangeStatus = (status, id) => {
        fetchData(`account/change-status`, 'POST', {'id': id, 'status': status}, true, false, (res) => {
            reloadDataTable(dt)
        }, (err) => {})
    }

    const handleViewProfile = (user_id) => {
        navigate(`/accounts/${profilePath}/profile/${user_id}`) 
    }   
    
    const handleEditProfile = (user_id) => {
        navigate(`/accounts/${profilePath}/edit/${user_id}`) 
    }   

    const handlePermission = (user_id) => {
        fetchData('get-modules-with-permission', 'post', {'user_id': user_id}, true, false, (res) => {
            if(res.status && res.data){
                setModules('')
                setModules(res.data)
                let module_ids = []
                res.data.modules.forEach(module => {
                    if(module.checked){
                        module_ids.push(module.id)
                    }
                })

                setModule_ids(module_ids)
                setTimeout(setShowModal(true),400)
            }
        }, (err) => {})
    }

    const handleCheckboxChange = (e, moduleId) => {
        if (e.target.checked) {
            setModule_ids(prevModuleIds => [...prevModuleIds, moduleId]);
        } else {
            setModule_ids(prevModuleIds => prevModuleIds.filter(id => id !== moduleId));
        }
    };
    const handleClose = () => {
        setShowModal(false);
      };
    const handleSavePermission = () => {
        let account = {'user_id': modules.user_id, 'modules':module_ids}
        fetchData('update-permission', 'POST', account, true, false, (res) => {
            if(res.status){
                handleClose();
            }
        }, (err) => {})
    }

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | { _.upperFirst(accountType) } Accounts</title>
        </Helmet>
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2">
                        <h5 className="m-0 heading-lines fw-medium pb-2">{ _.upperFirst(accountType) } Accounts</h5>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/accounts/${accountType}/create`} className="btn btn-blue px-3 mb-1 me-2 manage-responsive-btn" title={`Create ${ _.upperFirst(accountType) }`}>Create { _.upperFirst(accountType) }</Link>
                        <Link to={`/accounts/${accountType}/list${listType === 'draft' ? '': '/draft'}`} className="btn btn-warning manage-responsive-btn px-3 mb-1" title={`${ _.upperFirst(accountType) } ${listType === 'draft' ? 'List': 'Drafts'}`}>{ _.upperFirst(accountType) } {listType === 'draft' ? 'List': 'Drafts'}</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">
                                <Datatables dt_name="account_list"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Modal show={showModal} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
            <Modal.Title>Permission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modules ? modules.modules.map((module, i) => (
                    <label className="form-check mb-2" key={i}>
                        <input className="form-check-input disabled_color" name="modules" disabled={module.name === 'Dashboard' ? true : false} type="checkbox" value={module.id}
                            onChange={(e) => handleCheckboxChange(e, module.id)}
                            defaultChecked={module_ids.includes(module.id)}    />
                        <div className="form-check-label disabled_color">
                            {module.name}
                        </div>
                    </label>
                )) : ''}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="btn btn-blue" onClick={handleSavePermission}>
                Update
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default AccountList