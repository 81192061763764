import ReactPaginate from 'react-paginate';
import { useEffect } from "react";

function Pagination(props) {
    const changePage = (e) => {
        props.onPageChange(e);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    useEffect(() => {
        document.querySelectorAll('.pagination li').forEach((ele)=>{
            ele.classList.add('page-item')
        });
    })   
    return(
        <>
            { props.paginate_data.data && props.paginate_data.data.length ? 
                <div className={`col w-100 mt-${props.side ? 3 : 5}`}>
                    <nav aria-label="navigation">
                        <ReactPaginate
                            pageCount={props.paginate_data.last_page}
                            initialPage={props.paginate_data.current_page - 1}
                            forcePage={props.paginate_data.current_page - 1}
                            pageRangeDisplayed={4}
                            marginPagesDisplayed={2}
                            previousLabel="Previous"
                            nextLabel="Next"
                            containerClassName={`pagination pagination-sm justify-content-${props.side ? props.side : 'center'}`}
                            activeClassName="page-item active"
                            disabledClassName="page-item disabled"
                            breakLinkClassName="page-link text-center"
                            pageLinkClassName="page-link text-center"
                            previousLinkClassName="page-link text-center"
                            nextLinkClassName="page-link text-center"
                            onPageChange={changePage}
                            disableInitialCallback={true}
                        />
                    </nav>
                </div>
            : ''}
        </> 
    );
}
export default Pagination;