import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import TeacherCard from "../components/cards/Teacher";
import {fetchData, scrollbarSetToTop, showAlert} from "../components/Helper";
import Pagination from "../components/Pagination";

function Teacher() {
    const [currentPage, setCurrentPage] = useState(1);
    const [teacher, setTeacher] = useState("");
    const [level, setLevel] = useState("");
    const [search, setSearch] = useState(localStorage.getItem("search") ? localStorage.getItem("search") : "");
    const [system, setSystem] = useState("");
    const [subject, setSubject] = useState("");
    const [levelId, setLevelId] = useState("");
    const [systemId, setSystemId] = useState(
        localStorage.getItem("system_id") ? localStorage.getItem("system_id") : ""
    );
    const [subjectId, setSubjectId] = useState(
        localStorage.getItem("subject_id") ? localStorage.getItem("subject_id") : ""
    );
    const [total, setTotal] = useState(0);

    useEffect(() => {
        scrollbarSetToTop();
        async function getTeacher() {
            fetchData(
                `get-user-with-details/4/yes?page=${currentPage}&search=${search}`,
                "GET",
                "",
                false,
                false,
                (res) => {
                    if (res.status && res.data) {
                        setTeacher(res.data);
                    }
                },
                (err) => {}
            );
        }

        getTeacher();
        fetchData(
            `educational-classification-list/level`,
            "GET",
            "",
            false,
            false,
            (res) => {
                setLevel(res.data);
            },
            (err) => {}
        );
        fetchData(
            `educational-classification-list/system`,
            "GET",
            "",
            false,
            false,
            (res) => {
                setSystem(res.data);
            },
            (err) => {}
        );
        fetchData(
            `educational-classification-list/subject`,
            "GET",
            "",
            false,
            false,
            (res) => {
                setSubject(res.data);
            },
            (err) => {}
        );
    }, [currentPage, search]);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0);
    };
    const searchTeacher = (e, search_query = "") => {
        e.preventDefault();
        let search_q = search_query ? search_query : e.target.value;
        setSearch(search_q);
        if (search && search.length >= 2) {
            fetchData(
                `get-user-with-details/4/yes?search=${search_q}`,
                "GET",
                "",
                false,
                false,
                (res) => {
                    setTeacher(res.data);
                    setTotal(res.data.total);
                },
                (err) => {}
            );
        }
    };
    const submitRestBtn = () => {
        setLevelId("");
        setSystemId("");
        setSubjectId("");
        setTotal(0);
        setSearch("");
        fetchData(
            `get-user-with-details/4/yes`,
            "GET",
            "",
            false,
            false,
            (res) => {
                setTeacher(res.data);
                setTotal(res.data.total);
            },
            (err) => {}
        );
    };
    const advanceSearch = (e) => {
        e.preventDefault();
        if (levelId || systemId || subjectId) {
            fetchData(
                `get-user-with-details/4/yes?search=${search}&system_id=${systemId}&level_id=${levelId}&subject_id=${subjectId}`,
                "GET",
                "",
                false,
                false,
                (res) => {
                    if (res.status && res.data) {
                        setTeacher(res.data);
                        setTotal(res.data.total);
                    }
                },
                (err) => {}
            );
        } else {
            let msg = {message: "Please fill some record.", status: false};
            showAlert(msg);
        }
    };

    const submitSearchBtn = (e) => {
        e.preventDefault();
        if (search && search.length >= 3) {
            searchTeacher(e, search);
        } else {
            let msg = {message: "Please fill minimum three characters.", status: false};
            showAlert(msg);
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Teachers</title>
            </Helmet>
            <section className="bg-blue py-4 position-relative overflow-hidden">
                <img
                    src="images/courses-bg.png"
                    className="position-absolute top-0 opacity-25 zindex-0 pointer-none"
                    alt="weteach"
                />
                <div className="container position-relative zindex-2">
                    <div className="row">
                        <div className="col-lg-12 pb-3">
                            <h2 className="heading-lines text-white m-0 pt-3">Our Staff</h2>
                        </div>
                    </div>
                    <div className="row g-3">
                        <form className="col-sm-12" id="admin-teacher-search">
                            <div className="input-group input-group-xl">
                                <input
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="Search..."
                                    value={search}
                                    onChange={searchTeacher}
                                />
                                <button
                                    className="input-group-text btn-orange px-4"
                                    id="basic-addon2"
                                    title="Search"
                                    onClick={submitSearchBtn}
                                >
                                    <i className="bi-search fs-30px"></i>
                                </button>
                            </div>
                            <div className="ps-3 py-2">
                                <a
                                    className="text-body text-decoration-none fw-medium"
                                    data-bs-toggle="collapse"
                                    title="Advance Search"
                                    href="#collapseExample"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                >
                                    Advance Search
                                    <i className="bi-arrow-down-up ms-1 fs-18px"></i>
                                </a>
                            </div>
                            <div className="collapse" id="collapseExample">
                                <div className="card border-0 p-2 p-sm-3">
                                    <div className="row m-0">
                                        <div className="col-sm-12 pt-2">
                                            <h5>Advance Search</h5>
                                        </div>
                                        <div className="col-sm-6 col-md-4 mb-2">
                                            <select
                                                className="form-select"
                                                name="system"
                                                title="System"
                                                value={systemId}
                                                onChange={(e) => setSystemId(e.target.value)}
                                                role="button"
                                            >
                                                <option value="">---System---</option>
                                                {system
                                                    ? system.map((system_detail) => {
                                                          return (
                                                              <option key={system_detail.id} value={system_detail.id}>
                                                                  {system_detail.name}
                                                              </option>
                                                          );
                                                      })
                                                    : ""}
                                            </select>
                                        </div>
                                        <div className="col-sm-6 col-md-4 mb-2">
                                            <select
                                                className="form-select"
                                                name="level"
                                                title="Level"
                                                value={levelId}
                                                onChange={(e) => setLevelId(e.target.value)}
                                                role="button"
                                            >
                                                <option value="">---Level---</option>
                                                {level
                                                    ? level.map((level_detail) => {
                                                          return (
                                                              <option key={level_detail.id} value={level_detail.id}>
                                                                  {level_detail.name}
                                                              </option>
                                                          );
                                                      })
                                                    : ""}
                                            </select>
                                        </div>
                                        <div className="col-sm-6 col-md-4 mb-2">
                                            <select
                                                className="form-select"
                                                name="subject"
                                                title="Subject"
                                                value={subjectId}
                                                onChange={(e) => setSubjectId(e.target.value)}
                                                role="button"
                                            >
                                                <option value="">---Subject---</option>
                                                {subject
                                                    ? subject.map((subject_detail) => {
                                                          return (
                                                              <option key={subject_detail.id} value={subject_detail.id}>
                                                                  {subject_detail.name}
                                                              </option>
                                                          );
                                                      })
                                                    : ""}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row m-0 justify-content-between pt-3 pb-3">
                                        <div className="col">
                                            <p className="btn position-relative pointer-none fw-medium">
                                                Results
                                                <span className="position-absolute top-0 mt-1 px-2 start-100 translate-middle badge rounded-pill bg-orange">
                                                    {total}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col text-end">
                                            <button
                                                type="button"
                                                className="btn btn-info text-white me-1 fw-medium px-4 mb-2"
                                                title="Reset"
                                                onClick={submitRestBtn}
                                            >
                                                Reset
                                            </button>
                                            <button
                                                onClick={advanceSearch}
                                                className="btn btn-orange fw-medium px-4 border-0 mb-2"
                                                title="Search"
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            <section className="cources-section py-4">
                <div className="container">
                    <div className="row">
                        {teacher.data ? (
                            teacher.data.length === 0 ? (
                                <h4 className="text-center w-100 rounded-10 py-5 text-danger">
                                    There is no matching Teacher.
                                </h4>
                            ) : (
                                teacher.data.map((item, i) => {
                                    return <TeacherCard teacher={item} key={i} />;
                                })
                            )
                        ) : (
                            Array.from(Array(8)).map((c, index) => (
                                <div className="col placeholder-wave" key={index}>
                                    <div className="course-item h-100">
                                        <div className="ratio ratio-4x3 bg-light">
                                            <div className="w-100 h-100 img-cover placeholder"></div>
                                        </div>
                                        <span className="course-tag placeholder w-25"></span>
                                        <div className="p-3">
                                            <h6 className="course-heading placeholder w-50">&nbsp;</h6>
                                            <p className="course-description placeholder"></p>
                                            <p className="course-description placeholder"></p>
                                            <p className="course-description placeholder w-50"></p>
                                            <div className="pt-2 d-flex justify-content-between align-items-center">
                                                <span className="btn btn-outline-blue placeholder w-25"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                <Pagination paginate_data={teacher} onPageChange={handlePageClick} />
            </section>
        </>
    );
}

export default Teacher;
