import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom"
import { fetchData } from "../components/Helper"

function VerifyEmail(){
    const VerifyEmailParams = useNavigate();
     const location = useLocation();

    useEffect(() => {
        if(location.search){
            fetchData(`verify-email${location.search}`, 'GET', '', false, false, (res) => {
                if (res.status){
                    VerifyEmailParams('/login')
                }
            }, (err) => {})
        }else{
            VerifyEmailParams('/')
        }
    }, [VerifyEmailParams, location.search])
    
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Verify Email</title>
        </Helmet>
        <section className="about-section py-5">
            <div className="container py-sm-4 py-5">
                <div className="row justify-content-center py-5">
                    <div className="col-sm-8 text-center">
                        <h2 className="fw-bold">Account Verification</h2>
                        <p className="opacity-75">We are verifying your account. Please do not press back button.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default VerifyEmail