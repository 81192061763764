import _ from "lodash";
import { useEffect, useState } from "react";
//import { useParams } from "react-router";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { fetchData, validateForm, intlTel_phone, validatePhone } from "../../components/Helper";
import { Helmet } from 'react-helmet';

function AccountCreate(){
    const navigate = useNavigate()
    const location = useLocation();
    const accountType = location.pathname.split("/")[2]
    const [accountData, setAccountData] = useState({country:'us', user_type: accountType})
    const [modules, setModules] = useState()
    const [users, setUsers] = useState()
    const [module_ids, setModule_ids] = useState([1])
    const [intlTel, setIntlTel] = useState()

    useEffect(() => {
        if(accountType === 'admin'){
            fetchData('get-modules-with-permission', 'post', '', true, false, (res) => {
                if(res.status && res.data){
                    setModules(res.data)
                }
            }, (err) => {})
        }

        if(accountType === 'student' || accountType === 'assistant'){
            let role_id = accountType === 'student' ? 5 : 4
            fetchData(`get-users/${role_id}`, 'GET', '', true, false, (res) => {
                if(res.status && res.data){
                    setUsers(res.data)
                }
            }, (err) => {})
        }

        setIntlTel(intlTel_phone('#phone', '#country', 'us'))
    }, [accountType])

    const handleInputChange = (e) => {
        setAccountData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            module_ids.push(+e.target.value)
        } else {
            module_ids.splice(module_ids.indexOf(+e.target.value), 1)
        }

        setModule_ids(module_ids)
    }

    const saveData = (e, status) => {
        if(validateForm(e) && validatePhone(intlTel, '#phone')){
            let account = {...accountData, 'status': status, 'phone': intlTel.getNumber()}
            if(accountType === 'admin'){
                account = {...account, 'modules': module_ids}
            }
            fetchData('account-create', 'POST', account, true, false, (res) => {
                if(res.status){
                    setAccountData({country:'us', user_type: accountType})
                    navigate(`/accounts/${accountType}/list`)
                }
            }, (err) => {})
        }
    }
    return(
        <section className="admin-wrapper">
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | { _.upperFirst(accountType) } Account Create</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2">
                        <h5 className="m-0 heading-lines fw-medium pb-2">{ _.upperFirst(accountType) } Account Create</h5>
                    </div>
                    <div className="col-auto mb-2">
                        <Link to={`/accounts/${accountType}/list`} className="btn btn-blue mb-1 me-2" title={`${ _.upperFirst(accountType) } List`}>{ _.upperFirst(accountType) } List</Link>
                        <Link to={`/accounts/${accountType}/list/draft`} className="btn btn-warning px-3 mb-1" title="Drafts">Drafts</Link>
                    </div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body p-sm-4 p-xl-5">
                                <form action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                    { accountType === 'student' || accountType === 'assistant' ? 
                                    <div className="mb-3 mb-sm-4 row mx-0">
                                        <label htmlFor="adminname" className="col-sm-3 col-xxl-2 col-form-label">{ accountType === 'student' ? 'Parent Link' : 'Link To Teacher' } </label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <select className="form-select" name="link_id" onChange={handleInputChange} required>
                                                <option value="">{ accountType === 'student' ? 'Please select a parent' : 'Please select a teacher' }</option>
                                                { users ? (
                                                    users.map((user, i) => {
                                                        return <option key={i} value={user.id}>{`${user.name} <${user.email}>`}</option>
                                                    })
                                                ) : ''}
                                            </select>
                                        </div>
                                    </div>
                                    : '' }
                                    <div className="mb-3 mb-sm-4 row mx-0">
                                        <label htmlFor="name" className="col-sm-3 col-xxl-2 col-form-label">Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <input type="text" className="form-control" id="name" name="name" onChange={handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="mb-3 mb-sm-4 row mx-0">
                                        <label htmlFor="email" className="col-sm-3 col-xxl-2 col-form-label">Email<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <input type="text" className="form-control" id="email" name="email" onChange={handleInputChange} required/>
                                        </div>
                                    </div>
                                    <div className="mb-3 mb-sm-4 row mx-0">
                                        <label htmlFor="country" className="col-sm-3 col-xxl-2 col-form-label">Country<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <select className="form-select" name="country" id="country" onChange={handleInputChange} required>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-3 mb-sm-4 row mx-0">
                                        <label htmlFor="phone" className="col-sm-3 col-xxl-2 col-form-label">Whatsapp<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5 form-flag-height38">
                                            <input type="tel" className="form-control" id="phone" autoComplete="off" name="phone" data-intl-tel-input-id="0" required/>
                                        </div>
                                    </div>
                                    <div className="mb-3 mb-sm-4 row mx-0">
                                        <label htmlFor="password" className="col-sm-3 col-xxl-2 col-form-label">Password<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <input type="password" className="form-control" id="password" name="password" onChange={handleInputChange} autoComplete="off" required/>
                                        </div>
                                    </div>
                                    <div className="mb-3 mb-sm-4 row mx-0">
                                        <label htmlFor="confirm_password" className="col-sm-3 col-xxl-2 col-form-label">Confirm Password<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="col-sm-9 col-lg-6 col-xxl-5">
                                            <input type="password" className="form-control" id="confirm_password" password="confirm_password" name="confirm_password" autoComplete="password" onChange={handleInputChange} required/>
                                        </div>
                                    </div>
                                    { accountType === 'teacher' ? 
                                    <div className="mb-3 mb-sm-4 row mx-0">
                                            <label htmlFor="frontend" className="col-sm-3 col-xxl-2 col-form-label">Appearance on front end<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="form-check form-check-inline mt-2">
                                                    <input className="form-check-input" type="radio" name="appearance" value="yes" id="yes" onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="yes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="appearance" value="no" id="no" defaultChecked onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="no">
                                                        No
                                                    </label>
                                                </div>  
                                            </div>                                          
                                    </div>
                                    : '' }
                                    { accountType === 'admin' ? 
                                    <div className="mb-3 mb-sm-4 row mx-0">
                                        <label htmlFor="adminroles" className="col-sm-3 col-xxl-2 col-form-label">Permissions</label>
                                        <div className="col-sm-9">
                                            <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4">
                                                { modules ? modules.modules.map((module, i) => (
                                                    <div className="col-12 col-sm-6 pt-2" key={i}>
                                                        <label className="form-check">
                                                        <input className="form-check-input" defaultChecked={module.name === 'Dashboard'} disabled={module.name === 'Dashboard'} name="modules" type="checkbox" value={module.id} onChange={handleCheckboxChange} />
                                                            <div className="form-check-label">
                                                                { module.name }
                                                            </div>
                                                        </label>
                                                    </div>
                                                )) : ''}
                                            </div>
                                        </div>
                                    </div>
                                    : '' }
                                    <div className="row mx-0">
                                        <div className="col-sm-9 offset-sm-3 offset-xxl-2">
                                            <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={(e) => saveData(e, 'pending')} title="Create">Create</button>
                                            <button type="button" className="btn btn-outline-success" onClick={(e) => saveData(e, 'draft')} title="Save Draft">Save Draft</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AccountCreate