import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Datatables, {reloadDataTable, reloadUrlDataTable} from "../components/Datatables";
import {createRoot} from "react-dom/client";
import {fetchData} from "../components/Helper";
import {useLocation} from "react-router-dom";

export default function Currency() {
    const location = useLocation();
    const handleChangeStatus = (status, id) => {
        fetchData(
            `country/change-status`,
            "POST",
            {id: id, status: status, pathname: location.pathname},
            true,
            false,
            (res) => {
                reloadDataTable(dt);
            },
            (err) => {}
        );
    };
    const [dt] = useState({
        dt_url: `countries?type=admin`,
        dt_name: "countries_list",
        dt_column: [
            {data: "id", name: "id", title: "#ID"},
            {data: "name", name: "name", title: "Country Name", width: 255},
            {data: "currency_type", name: "currency_type", title: "Currency Type", width: 569},
            {data: "currency_price", name: "currency_price", title: "Currency Price", width: 569},
            {data: "increase_amount", name: "increase_amount", title: "Increase 10% Price (If Price > 5 )", sortable:false, searchable:false, width: 569},
            {data: "status", name: "status", title: "Status"},
            {
                data: "id",
                title: "Action",
                sortable: false,
                searchable: false,
                class: "text-center text-nowrap",
                width: 160,
            },
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<>
                        <span className="badge bg-success me-2">1 EGP = {rowData.currency_price} {rowData.currency_type}</span>
                        <span className="badge bg-secondary">1 {rowData.currency_type} = {rowData.converted_price} EGP</span>
                    </>)
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<>
                        <span className="badge bg-info me-2">{rowData.increase_amount}</span>
                    </>)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : '' }
                    { rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : '' }
                    </>)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <>
                            {rowData.status === "inactive" ? (
                                <button
                                    type="button"
                                    className="btn btn-success py-2px m-2px w-100px"
                                    title="Activate"
                                    onClick={() => handleChangeStatus("active", rowData.id)}
                                >
                                    Activate
                                </button>
                            ) : (
                                ""
                            )}

                            {rowData.status === "active" ? (
                                <button
                                    type="button"
                                    className="btn btn-danger py-2px m-2px w-100px"
                                    title="Deactivate"
                                    onClick={() => handleChangeStatus("inactive", rowData.id)}
                                >
                                    Deactivate
                                </button>
                            ) : (
                                ""
                            )}
                        </>
                    );
                },
            },
        ],
    });
    useEffect(() => {
        reloadUrlDataTable(dt, `countries/type=admin`);
    }, [dt]);
    return (
        <div>
            <section className="admin-wrapper">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>WeTeach | Currency</title>
                </Helmet>
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Currency</h5>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <Datatables dt_name="countries_list" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
