import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { fetchData, scrollbarSetToTop } from "../components/Helper"
import CourseCard from "../components/cards/Course"
import { Helmet } from "react-helmet"

function TeacherInfo() {
    const [teacher, setTeacher] = useState()
    const teacherId = useParams().teacher_id

    useEffect(() => {
        scrollbarSetToTop();
        fetchData(`get-profile/${teacherId}`, 'GET', '', false, false, (res) => {
            if (res.status && res.data) {
                setTeacher(res.data)
            }
        }, (err) => {})
    }, [teacherId])

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Teacher's Details</title>
        </Helmet>
            <section className="bg-blue py-4 position-relative overflow-hidden">
                <img src="/images/courses-bg.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="weteach"/>
                <div className="container position-relative zindex-2">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="heading-lines text-white m-0 pt-3">Teacher Info</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cources-section py-4 py-sm-5">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            {teacher ?
                                <div className="card border-0 shadow-sm rounded-10">
                                    <div className="card-body p-sm-4">
                                        <div className="d-sm-flex align-items-start">
                                            <div className="ratio ratio-1x1 rounded-10 overflow-hidden mb-3 mmw-200px">
                                                <img src={teacher.user_details.image} alt={teacher.name} className="img-cover" />
                                            </div>
                                            <div className="ps-sm-4">
                                                <div className="mb-3">
                                                    <strong className="d-block fs-14px fw-medium text-muted">Teacher Name</strong>
                                                    <span className="fs-17px d-block">{teacher.name}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <strong className="d-block fs-14px fw-medium text-muted">Teaching Experience</strong>
                                                    <span className="fs-17px">{teacher.user_details.experience}</span>
                                                </div>
                                                <div className="mb-4">
                                                    <strong className="d-block fs-14px fw-medium text-muted">Rating</strong>
                                                    <div className="star-rating">
                                                        <span style={{width:Number(teacher.user_details.rating * 20)+'%'}}></span>
                                                    </div>
                                                    <div className="fs-17px">{teacher.user_details.rating} out of 5</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column mt-2">
                                            <div className="mb-3 border-lightblue rounded p-3">
                                                <strong className="d-block fs-14px fw-medium text-muted">Brief Experience </strong>
                                                <span className="fs-17px">{teacher.user_details.brief ? teacher.user_details.brief : ''}</span>
                                            </div>
                                            { teacher.feedbacks && teacher.feedbacks.length > 0 ?
                                                <div className="mb-3 border-lightblue rounded p-3 pb-0">
                                                    <strong className="d-block fs-14px fw-medium text-muted">Feedback </strong>
                                                    {teacher.feedbacks.map((feedback_details, i) => {
                                                        return (
                                                            <blockquote className="border-lightblue rounded p-3 mt-1" key={i}>
                                                                <p className="opacity-90 m-0">
                                                                    {feedback_details.description}
                                                                </p>
                                                                <small className="text-muted fs-12px">&mdash; {feedback_details.name}</small>
                                                            </blockquote>
                                                        )
                                                    })}
                                                </div>
                                                : ''
                                            }
                                            <div className="border-lightblue rounded p-3">
                                                <strong className="d-block fs-14px fw-medium text-muted">Taught Courses </strong>
                                                <div className="cources-section pt-3" >
                                                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 pb-0 row-cols-lg-4 g-2" >
                                                        { teacher.course ? <CourseCard course_details={teacher.course} hide='yes' /> : '' }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TeacherInfo
