import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import _ from 'lodash';
import { useEffect, useState } from "react";
import Datatables, { initDataTable, reloadDataTable, reloadUrlDataTable, destroyDataTable } from '../../components/Datatables';
import { createRoot } from "react-dom/client";
import { Helmet } from "react-helmet";

function HelpVideoList(){
    const navigate = useNavigate()
    const location = useLocation();
    const video_type = useParams().video_type
    const [, setNavigateTo] = useState('');
    useEffect(() =>{
        setNavigateTo(`/get-videos/${video_type}/edit`)
        
    }, [video_type])
    


    const [dt] = useState({
        dt_url: `get-videos/${video_type}`,
        dt_name: 'get-videos-list',
        dt_column: [
            { data:"id", name:'id', title: "#ID"},
            { data:"slug", name:'slug', title:"Section"},
            { data:"embed_id", name:'embed_id', title:"Video URL"},
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-nowrap text-center", width:160}
        ],
        
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(`${ _.startCase(rowData.slug)}`)
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" data-tabname={video_type} onClick={(e) => handleEditForm(rowData)}>
                            Edit
                        </button>
                        {/* <button type="button" className="btn btn-danger py-2px m-2px" title="Delete" onClick={() => handleChangeStatus('delete', rowData.id)}>
                            Delete
                        </button> */}
                                                    
                    </>)
                },
            },
        ]
    })

    // const handleChangeStatus = (status, id) => {
    //     fetchData(`help-video/change-status`, 'POST', {'id': id, 'status': status}, true, false, (res) => {
    //         reloadDataTable(dt)
    //     }, (err) => {})
    // }

    const handleEditForm = (rowData) => {
        document.querySelectorAll('button[data-bs-toggle="tab"].active').forEach((e)=> navigate(`/get-videos/${e.dataset.tablename}/edit`, { ...location, state: rowData}))
    }

    useEffect(() => {
        initDataTable(dt)
        document.querySelectorAll('button[data-bs-toggle="tab"]').forEach((e)=> {
            e.addEventListener('show.bs.tab', function (event) {
                navigate(`/get-videos/${event.target.dataset.tablename}/list`)
                destroyDataTable(dt)
                reloadDataTable(dt)
                reloadUrlDataTable(dt, `get-videos/${event.target.dataset.tablename}`)
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dt])

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Help Videos</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Help Videos</h5>
                        </div>
                        <div className="col-auto mb-2">
                            {/* <Link to={`/get-videos/${video_type}/create`} className="btn btn-blue px-3 mb-1 me-1" title={`Add ${ _.upperFirst(video_type) }`}>Add { _.upperFirst(video_type) } Video</Link> */}
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={video_type === 'student' ? 'nav-link text-blue active fw-medium' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target='studentbox' type="button" role="tab" data-tablename="student" title="Student">Student</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={video_type === 'parent' ? 'nav-link text-blue active fw-medium' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target='parentbox' type="button" role="tab" data-tablename="parent" title="Parent">Parent</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={video_type === 'teacher' ? 'nav-link text-blue active fw-medium' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target='teacherbox' type="button" role="tab" data-tablename="teacher" title="Teacher">Teacher</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={video_type === 'assistant' ? 'nav-link text-blue active fw-medium' : 'nav-link text-blue fw-medium'} data-bs-toggle="tab" data-bs-target='assistantbox' type="button" role="tab" data-tablename="assistant" title="Assistant Teacher">Assistant Teacher</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content border border-top-0">
                                        <div className="tab-pane fade p-3 show active" id={`#${video_type}box`} role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <Datatables dt_name="get-videos-list"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HelpVideoList