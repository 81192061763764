import { useEffect, useState} from "react";
import { fetchData} from "../../components/Helper";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

function FeedbackCard() {
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    fetchData('feedback/yes', 'GET', '', false, false, (res) => {
      if (res.status && res.data) {
        setFeedback(res.data);
      }
    }, (err) => {});
  }, []);

  const responsiveOptions = {
    0: {
      nav: true,         
      dots: false,
      items: 1, // Number of items to show at 0px screen width
    },
    600: {
      nav: true,         
      dots: false,
      items: 2, // Number of items to show at 600px screen width
    },
    1000: {
      nav: true,         
      dots: false,
      items: 4, 
    },
  };


  return (  
    <div>  
      <div className='container-fluid'>            
        {feedback.length > 0 && (
          <OwlCarousel items={feedback.length} className="owl-carousel owl-arrow-dots" nav margin={8} responsive={responsiveOptions}>
            {feedback.map((feedbackDetails, i) => (
              <div className="item" key={i}>
                <div className="feedback-item">
                  <div className="feedback-topbox">
                    {feedbackDetails.feedback_description}
                  </div>
                  <div className="feedback-bottombox">
                    <img src={feedbackDetails.feedback_image} className="mmw-80px" alt="weteach" />
                    <h5>{feedbackDetails.teacher_name}</h5>
                    <p>{feedbackDetails.course_name}</p>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        )}
      </div>  
    </div>  
  );
}

export default FeedbackCard;
