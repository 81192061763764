import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Context from "./Context";
import MyProfile from "../modules/myaccount/MyProfile";
import { fetchData } from "./Helper";
// import MaterialDetails from "../modules/myaccount/MaterialDetails";
import AllCourse from '../modules/myaccount/AllCourse'
import MySchedule from "../modules/myaccount/MySchedule";
import MyWallet from "../modules/myaccount/MyWallet";
import ProfileChangePassword from "../modules/myaccount/ProfileChangePassword";
import ClassRecordingDetails from "../modules/myaccount/ClassRecordingDetails";
import StudentDetails from "../modules/myaccount/StudentDetails";
import PerformanceDetails from "../modules/myaccount/PerformanceDetails";
import NoteLibraryDetails from "../modules/myaccount/NoteLibraryDetails";
import AssignmentQuizzDetails from "../modules/myaccount/AssignmentQuizzDetails";


function FrontComponent(){
    const [context] = useContext(Context)
    const [student, setStudent] = useState();
    const location = useLocation();
    const navigate  = useNavigate();
    const [stdId, setStdId] = useState(location.state && location.state.user_id ? location.state.user_id : context.auth.id);
    const [courseId, setCourseId] = useState(location.state && location.state.course_id ? location.state.course_id : '');
    const [videos, setVideos] = useState('');
    const role = context && context.auth && context.auth.role_id ;
    useEffect(() => {
        if (context && context.auth && context.auth.role_id === 5) {
            fetchData(`get-parent-student/${context.auth.id}`, 'GET', '', true, false, (res) => {
                setStudent(res.data)
            }, (err) => { });
        }
    }, [context,courseId])

    useEffect(() => {
        fetchData(`get-videos/${role}`, 'GET', '', true, false, (res) => {
            setVideos(res.data)
        }, (err) => {});
    },[role])

    const getStudentId = (e) => {
        setStdId(e.target.value)
        navigate({ ...location, state: {user_id: e.target.value, course_id: courseId }});
    }

    const getCourseId = (id) => {
        setCourseId(id)
        localStorage.setItem("course_id",id)
    }

    return(
        <>
            { context && context.site && context.auth && (context.auth.role_id !== 1 && context.auth.role_id !== 2) ?
            <>
                <div className="col-md-8 col-sm-12">
                    <div className="card rounded-10 border-lightblue">
                        <div className="card-header h5 py-3 border-0 bg-lightblue fw-bold d-flex justify-content-between align-items-center">
                            <div className="fw-bold text-capitalize" id="group-title"></div>
                            {  videos && videos.map((ele, num) => <i className={`bi bi bi-question-circle-fill ms-2 help-icon ${location.pathname.match(ele.slug) ? '' : 'd-none'}`} key={num} title="Help" data-bs-toggle="modal" data-bs-target={`#${ele.slug.replace(/-/g, '')}`}></i> ) }
                            
                            {context && context.auth && context.auth.role_id === 5 ?
                                <div className="select-option">
                                    <select className="form-select form-select-sm" disabled={location.pathname.match('/details') ? true : false} aria-label="Default select example" onChange={getStudentId} value={stdId}>
                                        { location.pathname.match('/my-profile') ? <option value={context.auth.id}>My Profile</option> : <option value=''>-- Select --</option> }
                                        {student ? student.map((student_details) => {
                                            return (<option key={student_details.id} value={student_details.id}>{student_details.name}</option>)
                                        }) : ''}
                                    </select>
                                </div> : ''}
                        </div>
                        { location.pathname.match('/change-password') ? <ProfileChangePassword student_id={stdId}/> : '' }
                        { location.pathname.match('/my-profile') ? <MyProfile student_id={stdId}/> : '' }
                        { location.pathname.match('/my-schedule') ? <MySchedule student_id={stdId}/> : '' }
                        { location.pathname.match('/my-wallet') ? <MyWallet student_id={stdId}/> : '' }
                        { location.pathname.match('/my-classes') ? <AllCourse type="timing" student_id={stdId} getCourseId={getCourseId}/> : '' }
                        { location.pathname.match('/my-subjects') ? <AllCourse type="timing" student_id={stdId} getCourseId={getCourseId}/> : ''}
                        { location.pathname.match('/my-students') ? <AllCourse type="my-student" student_id={stdId} getCourseId={getCourseId}/> : '' }
                        { location.pathname.match('/library') && location.pathname !== '/library/details' ? <AllCourse type="library" student_id={stdId} getCourseId={getCourseId}/> : '' }
                        { location.pathname.match('/recordings') ? <AllCourse type="recording" student_id={stdId} getCourseId={getCourseId}/> : '' }
                        { location.pathname.match('/assignments') ? <AllCourse type="assignment" student_id={stdId} getCourseId={getCourseId}/> : '' }
                        { location.pathname.match('/quizzes') ? <AllCourse type="quizz" student_id={stdId} getCourseId={getCourseId}/>  : ''}
                        { location.pathname.match('/notes') ? <AllCourse type="note" student_id={stdId} getCourseId={getCourseId}/> : ''} 
                        { location.pathname.match('/performance') && !location.pathname.match('/performance/details') ? <AllCourse type="performance" student_id={stdId} getCourseId={getCourseId}/> : '' }

                        { location.pathname.match('/timing/details') && stdId && (courseId || localStorage.getItem("course_id")) ?  <ClassRecordingDetails material="timing" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/note/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <NoteLibraryDetails material="note" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/library/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <NoteLibraryDetails material="library" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/assignment/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <AssignmentQuizzDetails material="assignment" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/recording/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <ClassRecordingDetails material="recording" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/quizz/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <AssignmentQuizzDetails material="quizz" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''}
                        { location.pathname.match('/my-student/details') && stdId && (courseId || localStorage.getItem("course_id")) ? <StudentDetails material="my-student" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''}
                        { location.pathname.match('/performance/details') && stdId && (courseId || localStorage.getItem("course_id")) ? <PerformanceDetails material="performance" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''}

                        {/* { location.pathname.match('/timing/details') && stdId && (courseId || localStorage.getItem("course_id")) ?  <MaterialDetails material="timing" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/note/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <MaterialDetails material="note" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/library/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <MaterialDetails material="library" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/assignment/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <MaterialDetails material="assignment" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/recording/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <MaterialDetails material="recording" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} 
                        { location.pathname.match('/quizz/details')  && stdId && (courseId || localStorage.getItem("course_id")) ? <MaterialDetails material="quizz" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''}
                        { location.pathname.match('/my-student/details') && stdId && (courseId || localStorage.getItem("course_id")) ? <MaterialDetails material="my-student" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''}
                        { location.pathname.match('/performance/details') && stdId && (courseId || localStorage.getItem("course_id")) ? <MaterialDetails material="performance" student_id={stdId} course_id={courseId || localStorage.getItem("course_id")}/> : ''} */}
                    </div>
                </div>
            </>
            : ''}
        </>
    )
}

export default FrontComponent