import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import Datatables, { initDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { Helmet } from "react-helmet";
import _ from "lodash";

function CourseClasses(){

    const [dt] = useState({
        dt_url: `course-classes/active`,
        dt_name: 'classes-list',
        dt_column: [
            { data: "id", name: 'id', title: "#ID" },
            { data: "class_id", name: `class_id`, title: "Class ID" },
            { data: "name", name: `courses.name`, title: "Course Name" },
            { data: "reference_class", name: 'reference_class', title: "Timing" },
            { data: "type", name: 'type', title: "Meeting Type", class: "text-nowrap" },
            { data: "class_date", name: 'class_date', title: "Date", class: "text-nowrap" },
            { data: "start_class_time", name: 'start_class_time', title: "Start Time", class: "text-nowrap" },
            { data: "end_class_time", name: 'end_class_time', title: "End Time", class: "text-nowrap" },
            { data: "status", name: 'status', title: "Status" },
            { data: "created_at", name: 'created_at', title: "Class Created Date", class: "text-nowrap" },
        ],
        dt_column_defs: [
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(`${_.upperFirst(rowData.type)} (${_.upperFirst(rowData.meeting_type)})`)
                },
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        {rowData.status === "over" ? <span className="text-primary fw-medium">Over</span> : ''}
                        {rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : ''}
                        {rowData.status === "inactive" ? <span className="text-danger fw-medium">Inactive</span> : ''}
                        {rowData.status === "draft" ? <span className="text-primary fw-medium">Draft</span> : ''}
                    </>)
                },
            }
        ],
    })

    useEffect(() => {
        initDataTable(dt)
    }, [dt])

    const handleChangeTab = (type) => {
        reloadUrlDataTable(dt, `course-classes/${type}`)
    }

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Classes</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Classes</h5>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue active fw-medium" data-bs-toggle="tab" data-bs-target="#classes-box" type="button" role="tab" data-tablename="active" title="Active" onClick={() => handleChangeTab('active')}>Active</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue  fw-medium" data-bs-toggle="tab" data-bs-target="#classes-box" type="button" role="tab" data-tablename="inactive" title="Inactive" onClick={() => handleChangeTab('inactive')}>Inactive</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" data-bs-toggle="tab" data-bs-target="#classes-box" type="button" role="tab" data-tablename="over" title="Over" onClick={() => handleChangeTab('over')}>Over</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content border border-top-0">
                                        <div className="tab-pane fade p-3 show active" id="classes-box" role="tabpanel">
                                            <div className="table-responsive p-1">
                                                <Datatables dt_name="classes-list"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CourseClasses