import React, { useEffect } from 'react'
import { useContext } from "react"
import Context from "../../components/Context";
import ChangePassword from '../../components/ChangePassword';

function ProfileChangePassword(){
    const [context] = useContext(Context) 

    useEffect(() => {
        document.title = 'WeTeach | Change Password';
        if(context && context.auth && (context.auth.role_id !== 1 || context.auth.role_id !== 2)){
            let title = document.getElementById('group-title');
            if(title){
                title.innerHTML = 'Change Password';
            }
        }
    }, [context])
    
    return (
        <>
        { context && context.auth ? 
            context.auth.role_id === 1 || context.auth.role_id === 2 ?
                <section className="admin-wrapper">
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-between">
                            <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Change Password</h5>
                            </div>
                        </div>
                        <div className="row g-2 g-sm-3 g-lg-4">
                            <div className="col-sm-12">
                                <div className="card border-0 shadow-sm rounded-10">
                                    <div className="card-body p-sm-4 p-xl-5">
                                        <ChangePassword/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                :
                <ChangePassword/>
                
        : '' }
        </>
    )
}

export default ProfileChangePassword