import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import Context from '../../../components/Context';
import _ from 'lodash';

function MaterilDetailsBoby({ roleId, materialType, statusType, setStatusType, setCurrentPage, pagination, setMaterialId, children }) {
    const [context] = useContext(Context);
    const navigate = useNavigate();

    const handleGoBack = (e) => {
        if (e.type === 'click') {
            materialType === 'timing' ? navigate (context.auth.role_id === 3 || context.auth.role_id === 4 ? '/my-classes' : '/my-subjects')  : navigate(-1);
        }
      };

    return (
        context && context.auth && <>
            <div className="card-body">
                <div className="card-body pb-0">
                    <div className="row mb-2">
                        <div className="col-6 col-sm-7">
                            <button type="button" className="btn btn-orange btn-sm"  onClick={handleGoBack} title="Back" >
                                <i className="bi-arrow-left-circle-fill bi-3x ms-auto"></i> Back
                            </button>
                        </div>
                        <div className="col-6 col-sm-5 text-md-end">
                            {roleId && (roleId === 4 || roleId === 3) && ["assignment", "quizz", "note", "library"].includes(materialType) && <>
                                <button type="button" className="btn btn-blue btn-sm me-2 minw-120px mb-2" data-bs-toggle="modal" data-bs-target="#materialModal" title={`Create ${_.capitalize(materialType)}`} onClick={() => setMaterialId(0)}>
                                    Create {_.capitalize(materialType)}
                                </button>
                                <button type="button" className="btn btn-warning btn-sm minw-120px mb-2" onClick={() => { setStatusType(statusType === "active" ? "draft" : "active"); setCurrentPage(1); }} title={statusType === "active" ? `Draft ${_.capitalize(materialType)}` : `${_.capitalize(materialType)} List`}>
                                    {statusType === "active" ? `Draft ${_.capitalize(materialType)}` : `${_.capitalize(materialType)} List`}
                                </button>
                            </>}
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table align-middle table-lasttd-end">
                            <thead className="bg-light">
                                <tr>
                                    {materialType === "recording" && (<>
                                        <th>Date</th>
                                        <th>Recording Name</th>
                                        <th>Attended</th>
                                        <th>Status</th>
                                    </>)}

                                    {materialType === "timing" && (<>
                                        <th>Class Day </th>
                                        <th>Class Timing</th>
                                    </>)}

                                    {["note", "library", "performance", "assignment", "quizz"].includes(materialType) && (<>
                                        <th>Date</th>
                                        <th>Name</th>
                                        {["assignment", "quizz"].includes(materialType) && <th>Due Date</th>}
                                        {materialType === "performance" && <th>Type</th>}
                                         { (materialType === "performance") && (roleId === 5 || roleId === 6) &&
                                        <th>Rewarded Mark</th>
                                        }
                                        <th>Status</th>
                                    </>)}

                                    {materialType === "my-student" && (<>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Joining Date</th>
                                        <th>Withdrawal Date</th>
                                    </>)}

                                    {materialType === "performance" || materialType === "my-student" ? "" : <th className="text-center">Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {children}
                            </tbody>
                        </table>
                        {materialType !== "timing" && pagination}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MaterilDetailsBoby
