import React from "react";
import Context from "../../components/Context";
import { useEffect, useState, useContext } from "react";
import { fetchData } from "../../components/Helper";
import Pagination from "../../components/Pagination";
import _, { isEmpty } from "lodash";
import MaterilDetailsBoby from "./components/MaterilDetailsBoby";

function StudentDetails(props) {
    const materialType = props.material;
    const [context] = useContext(Context);

    const [materialList, setMaterialList] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (context && context.auth) {
            let api_url = `subject-type-list?id=${props.course_id}&type=${materialType}&user_id=${props.student_id}&status=active&page=${currentPage}`;
            fetchData(api_url, "GET", "", true, false, (res) => {
                setMaterialList(res.data);
                document.getElementById("group-title").innerHTML = res.data && res.data[1] ? res.data[1] : "";
            }, (err) => { });
        }
    }, [context, currentPage, materialType, props.student_id, props.course_id]);

    const handlePageClick = (data) => setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0);

    return (
        <>
            <MaterilDetailsBoby materialType={materialType} pagination={<Pagination paginate_data={materialList ? materialList[0] : ""} side="end" onPageChange={handlePageClick} />}>
                {materialList && materialList[0].data && !isEmpty(materialList[0].data) ? _.toArray(materialList[0].data).map((record, key) => {
                    return (
                        <tr key={key}>
                            <td>{record.name}</td>
                            <td>{record.email}</td>
                            <td>{record.date}</td>
                            <td>
                                {record.join_type === "External" && record.status === "Pending" ? "-" : record.withdraw_date}{" "}
                            </td>
                        </tr>
                    );
                }) : <tr><td colSpan={5} className="text-danger text-center">No data available</td></tr>}
            </MaterilDetailsBoby>
        </>
    );
}

export default StudentDetails;
