import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import Context from "./Context";

function FrontendFooter(){
    const [context] = useContext(Context)
    return(
        <>
        <footer className="footer-front">
            <img src="images/courses-bg.png" className="position-absolute top-0 opacity-10 zindex-0 pointer-none" alt="weteach"/>
            <div className="container position-relative">
                <div className="row">
                    <div className="col-sm-12 col-lg-4 mt-5">
                        <h5 className="text-white">Our Vision</h5>
                        <hr className="mt-2 h-2px w-40px opacity-60 text-blue"/>
                        <p className="pe-lg-5 mb-1">
                        Providing a convenient and efficient learning environment where students of  all needs can be catered to. Our goal is to make students reach a point where they can enhance their knowledge and skills, applying them while stepping into the real world.
                        </p>
                        <Link to="/about-us" className="small text-hoverblue" title="Read more">Read more &#10140;</Link>
                    </div>
                    <div className="col-sm-6 col-lg-3 offset-lg-1 mt-5">
                        <h5 className="text-white">Links</h5>
                        <hr className="mt-2 h-2px w-40px opacity-60 text-blue"/>
                        <ul className="p-0 m-0 list-unstyled">
                            <li className="mb-2">
                                <NavLink to="/home" title="Home">Home</NavLink>
                            </li>
                            <li className="mb-2">
                                <NavLink to="/courses" title="Courses">Courses</NavLink>
                            </li>
                            <li className="mb-2">
                                <NavLink to="/time-table" title="Time Table">Time Table</NavLink>
                            </li>
                            <li className="mb-2">
                                <NavLink to="/teachers" title="Teachers">Teachers</NavLink>
                            </li>
                            <li className="mb-2">
                                <NavLink to="/about-us" title="About Us">About Us</NavLink>
                            </li>
                            <li className="mb-2">
                                <NavLink to="/contact-us" title="Contact Us">Contact Us</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-6 col-lg-4 mt-5">
                        <h5 className="text-white">Contact Us</h5>
                        <hr className="mt-2 h-2px w-40px opacity-60 text-blue"/>
                        <ul className="p-0 m-0 list-unstyled">
                            <li className="mb-3">
                                <span className="d-flex">
                                    <i className="bi-geo-alt-fill bi-3x lh-1 mmw-20px mt-1 me-2"></i>
                                    The Burlington Tower, Business Bay, Dubai , UAE
                                </span>
                            </li>
                            <li className="mb-3">
                                <span className="d-flex flex-wrap">
                                    <i className="bi-whatsapp bi-1x lh-1 mmw-20px mt-1 me-2"></i>
                                    <div className="d-block">
                                        <a href="https://api.whatsapp.com/send?phone=201117822404&text=Hello">+20 111 7822 404</a><br/>
                                        <a href="https://api.whatsapp.com/send?phone=201067472903&text=Hello" className="mt-1 d-inline-block">+20 106 7472 903</a><br/>
                                        <a href="https://api.whatsapp.com/send?phone=971505291834&text=Hello" className="mt-1 d-inline-block">+971 50 529 1834</a>
                                    </div>
                                </span>
                            </li>
                            <li className="mb-3">
                                <span to="mailto:registrations@lms.com" className="d-flex">
                                    <i className="bi-envelope-fill bi-1x lh-1 mmw-20px mt-1 me-2"></i>
                                    <div className="d-block">
                                        <a href="mailto:admin@weteach.ae">admin@weteach.ae</a><br/>
                                    </div>
                                </span>
                            </li>
                        </ul>
                        <hr className="mt-4 h-2px w-40px opacity-60 text-blue"/>
                        <ul className="p-0 m-0 list-unstyled list-inline">
                            <li className="list-inline-item">
                                <a href="https://www.facebook.com/Weteach021/" title="Facebook">
                                    <i className="bi-facebook fs-20px"></i>
                                </a>
                            </li>
                            <li className="list-inline-item px-2"> 
                                <a href="https://api.whatsapp.com/send?phone=+971505291834&text=Hello" title="WhatsApp">
                                    <i className="bi-whatsapp fs-20px"></i>
                                </a>
                            </li>
                            <li className="list-inline-item pe-2">
                                <a href="https://www.instagram.com/weteach.ae/?fbclid=IwAR3yAU_YFlUWqQBqNxbieCDca_TrRwdHQwswpvJ5jE0UNeR1P7tN1cNeExs" title="Instagram">
                                    <i className="bi-instagram fs-20px"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="https://www.youtube.com/channel/UChsD8oxuSfCKSJMmOMXXjAA" title="YouTube">
                                    <i className="bi-youtube fs-20px"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-12 mt-2">
                        <hr/>
                    </div>
                    <div className="col-md-6 order-md-1 text-center text-md-end pt-3 small">
                        <ul className="p-0 m-0 list-unstyled list-inline">
                            <li className="list-inline-item">
                                <Link to="/privacy-policy" title="Privacy">Privacy</Link>
                            </li>
                            <li className="list-inline-item">|</li>
                            <li className="list-inline-item">
                                <Link to="/terms-and-conditions" title="Terms & Conditions">Terms & Conditions</Link>
                            </li>
                            <li className="list-inline-item">|</li>
                            <li className="list-inline-item">
                                <Link to="/faq" title="FAQ">FAQ</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-center text-md-start pt-3 small">
                        { context && context.site && context.site.copyright }
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}

export default FrontendFooter