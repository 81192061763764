import React from "react";
import { useLocation } from "react-router-dom";
import Context from "../../components/Context";
import { useEffect, useState, useContext } from "react";
import { fetchData, downloadFile } from "../../components/Helper";
import Pagination from "../../components/Pagination";
import _, { isEmpty } from "lodash";
import MaterilDetailsBoby from "./components/MaterilDetailsBoby";
import MaterilDetailsViewModal from "./components/MaterilDetailsViewModal";
import MaterilDetailsAddModal from "./components/MaterilDetailsAddModal";

function NoteLibraryDetails(props) {
    const location = useLocation();
    const userId = props.student_id;
    const courseId = props.course_id;
    const materialType = props.material;
    const [context] = useContext(Context);
    const roleId = context.auth.role_id;

    const [materialList, setMaterialList] = useState();
    const [totalGrade, setTotalGrade] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [statusType, setStatusType] = useState("active");
    const [reload, setReload] = useState(1);
    const [materialId, setMaterialId] = useState(0);
    const [submissionData, setSubmissionData] = useState();

    useEffect(() => {
        if (context && context.auth) {
            let api_url = `subject-type-list?id=${courseId}&type=${materialType}&user_id=${userId}&status=${statusType}&page=${currentPage}`;
            fetchData(api_url, "GET", "", true, false, (res) => {
                setMaterialList(res.data);
                document.getElementById("group-title").innerHTML = res.data && res.data[1] ? res.data[1] : "";
            }, (err) => { });
        }
    }, [context, currentPage, statusType, reload, courseId, materialType, userId]);

    const handlePageClick = (data) => setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0);

    const deleteMaterial = (id) => {
        fetchData(`delete-material/${id}`, "POST", { pathname: location.pathname }, true, false, (res) => {
            if (res.status) {
                setReload(reload + 1);
            }
        }, (err) => { });
    };

    const getMaterial = (id) => {
        fetchData(`get-material/${id}`, "POST", { course_id: courseId, user_id: userId, pathname: location.pathname }, true, false, (res) => {
            if (res.status && res.message === "") {
                if (res.data.type === "link") {
                    window.open(res.data.notes, "_blank");
                } else {
                    fetchData(`download-task/${id}`, "GET", "", true, false, (file) => downloadFile(file, `${res.data.module_type}-${res.data.download_name}`), (err) => { }, true);
                }
            }
        }, (err) => { });
    };

    const viewSubmission = (id) => {
        fetchData(`view-submission/${id}`, "GET", "", true, false, (res) => {
            if (res.status) {
                setSubmissionData(res.data);
                setTotalGrade(res.data[0].total_grade);
            }
        }, (err) => { });
    };

    return (
        <>
            <MaterilDetailsBoby roleId={roleId} materialType={materialType} statusType={statusType} setStatusType={setStatusType} setMaterialId={setMaterialId} setCurrentPage={setCurrentPage} pagination={<Pagination paginate_data={materialList ? materialList[0] : ""} side="end" onPageChange={handlePageClick} />}>
                {materialList && materialList[0].data && !isEmpty(materialList[0].data) ? _.toArray(materialList[0].data).map((record, key) => {
                    return (
                        <tr key={key}>
                            <td>{record.date}</td>
                            <td>{record.name}</td>
                            <td>{record.status}</td>
                            <td className="text-center">
                                {(roleId === 5 || roleId === 6) && <>
                                    <button type="button" className="btn btn-success btn-sm px-3 me-2" onClick={() => getMaterial(record.id)} title="Get">
                                        Get
                                    </button>
                                </>}
                                {(roleId === 3 || roleId === 4) && <>
                                    <button type="button" className="btn btn-dark btn-sm px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#materialModal" onClick={() => setMaterialId(record.id)} title="Edit">
                                        Edit
                                    </button>
                                    {statusType === "draft" ? <>
                                        <button type="button" className="btn btn-danger btn-sm px-3 me-2 mb-2" onClick={() => deleteMaterial(record.id)} title="Delete">
                                            Delete
                                        </button>
                                    </> : <>
                                        <button type="button" className="btn btn-blue btn-sm minw-120px mb-2" data-bs-toggle="modal" data-bs-target={`#viewModal`} onClick={() => viewSubmission(record.id)} title="Download">
                                            Download
                                        </button>
                                    </>}
                                </>}
                            </td>
                        </tr>
                    );
                }) : <tr><td colSpan={materialType === "note" ? 4 : 5} className="text-danger text-center">No data available</td></tr>}
            </MaterilDetailsBoby>

            <MaterilDetailsAddModal materialId={materialId} setMaterialId={setMaterialId} materialType={materialType} courseId={courseId} setReload={setReload} />

            <MaterilDetailsViewModal materialType={materialType}>
                {submissionData && !_.isEmpty(submissionData) ? submissionData.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{_.capitalize(item.name)}</td>
                            <td>{item.email}</td>
                        </tr>
                    );
                }) : <tr><td colSpan="5" className="text-danger text-center">No data available</td></tr>}
            </MaterilDetailsViewModal>
        </>
    );
}

export default NoteLibraryDetails;
