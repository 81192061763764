import React from 'react'
import Chart from 'chart.js/auto';
import { useEffect } from 'react';

export default function ChartComponant(props) {
    let chartStatus = Chart.getChart(props.id); 
    if (chartStatus !== undefined) {
        chartStatus.destroy();
    }

    useEffect(() => {
        var ctx = document.getElementById(props.id).getContext("2d");
        new Chart(ctx, {
            type:props.type ,
            labels:props.label,
            data: props.data,
            options: {
                indexAxis: props.axis,
                responsive: true,
                layout: {
                    padding: {
                        //left: -10,
                        right: 0,
                        top: 0,
                        bottom: -6,
                    }
                },
                maintainAspectRatio: false,
                scales: props.scale,
                legend: {
                    display: false
                },
                plugins: props.plugins
            }
        });
    })
    return(
        <canvas id={props.id} width="640" height="480"></canvas>
    )
}
