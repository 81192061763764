import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { fetchData, initialFormState, showAlert, validateForm } from '../../../components/Helper';
import { useLocation } from 'react-router-dom';

function MaterilDetailsSubmissionModal({ action,totalGrade, materialType, roleId, courseId, downloadReturnTask, setReload }) {
    const location = useLocation();
    const [data, setData] = useState({});
    const [opPerform, setOpPerform] = useState("create");
    const [restrictedPdf, setRestrictedPdf] = useState();
    const [fileExtension, setFileExtension] = useState();

    useEffect(() => {
        if (action && action.id) {
            if (action.type === "view-mark") {
                openModal("submissionForm", "mark");
                fetchData(`view-return-task/${action.id}`, "GET", "", true, false, (res) => {
                    if (res.status) {
                        setData({ id: res.data.id, marks: res.data.marks });
                    }
                }, (err) => { });
            } else if (action.type === "return-task") {
                openModal("submissionForm", "return");
                setRestrictedPdf(action.restricted_pdf);
                fetchData(`uplode-assignment/${action.id}`, "GET", "", true, false, (res) => {
                    if (res.status && res.data) {
                        setData({ pre_uploaded_task: res.data.submission, task_uploaded: true });
                    }
                }, (err) => { });
            } else if (action.type === "return-task-by-teacher") {
                openModal("submissionForm", "return");
                fetchData(`return-material-by-teacher/${action.id}`, "Get", "", true, false, (res) => {
                    if (res.status && res.data) {
                        setData({ pre_return_task: res.data.return, returned: true });
                    }
                }, (err) => { });
            }
        }
    }, [action])

    const openModal = (modal_name, type) => {
        initialFormState(modal_name);
        setOpPerform(type);
    };


    const handleInputChange = (e, key = false) => {
        if (_.isDate(e)) {
            document.getElementById(key).classList.remove("is-invalid");
            setData((prevState) => ({ ...prevState, [key]: e }));
        } else {
            document.getElementById(e.target.name).classList.remove("is-invalid");
            setData((prevState) => ({ ...prevState, [e.target.name]: e.target.type === "file" ? e.target.files[0] : e.target.value }));
            if (e.target.name === "upload_data") {
                const fileInput = e.target; // Reference to the file input element
                if (fileInput.files.length > 0) {
                  const uploadedFile = fileInput.files[0];
                  const fileName = uploadedFile.name;
                  const fileExtension = fileName.split('.').pop().toLowerCase();
                  setFileExtension(fileExtension);
                  if (restrictedPdf === 'yes' && fileExtension !== 'pdf') {
                    let msg = {message: `Please submit your work as a PDF file.`, status: false};
                    showAlert(msg);
                  } else {
                    setData((prevState) => ({
                      ...prevState,
                      pre_uploaded_task: '',
                    }));
                  }
                }
            }
        }
    };

    const removeMaterial = (closeModal) => {
        if (closeModal) {
            setData((prevState) => ({ ...prevState, pre_uploaded_task: "", task_uploaded: false }));
        } else {
            let msg = { message: `Please upload ${materialType}.`, status: false };
            showAlert(msg);
        }
    };

    const clientSideValidation = (e, data, formId = "materialForm") => {
        if (validateForm(e, formId)) {
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
                if ((key === "due_date" || key === "schedule_time") && value) {
                    formData.append(key, value.toISOString());
                } else {
                    formData.append(key, value);
                }
            }
            formData.append("pathname", location.pathname);
            return formData;
        } else {
            return false;
        }
    };

    const pageReload = (formId, formData, modalId) => {
        initialFormState(formId, formData);
        setReload(prev => prev+1);
        document.querySelector(`${modalId} [data-bs-dismiss=modal]`).click();
    };

    const returnMaterial = (e) => {
        let allData = { ...data, course_id: courseId, module_type: materialType, status: "return" };
        let validData = clientSideValidation(e, allData, "submissionForm");
        if (validData) {
            fetchData(`uplode-assignment/${action.id}`, "POST", validData, true, true, (res) => {
                if (res.status) {
                    pageReload("submissionForm", data, "#submissionModal");
                }
            }, (err) => { });
        }
    };

    const returnMaterialByTeacher = (id, method, e = false) => {
        let allData = { ...data, course_id: courseId, module_type: materialType };
        let validData = clientSideValidation(e, allData, "submissionForm");
        if (validData) {
            fetchData(`return-material-by-teacher/${id}`, "POST", validData, true, true, (res) => {
                if (res.status) {
                    pageReload("submissionForm", data, "#submissionModal");
                }
            }, (err) => { });
        }
    };

    const updateMark = (e) => {
        let allData = { ...data, course_id: courseId, module: materialType, status: "marked" };
        let validData = clientSideValidation(e, allData, "submissionForm");
        if (validData) {
            fetchData(`update-mark/${data.id}`, "POST", validData, true, true, (res) => {
                if (res.status) {
                    pageReload("submissionForm", data, "#submissionModal");
                }
            }, (err) => { });
        }
    };

    return (
        <div className="modal fade" id="submissionModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {_.capitalize(opPerform)} {_.capitalize(materialType)}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss={data && !data.task_uploaded && (roleId === 5 || roleId === 6) ? "modal" : "modal"} aria-label="Close" onClick={() => data && !data.task_uploaded && (roleId === 5 || roleId === 6) ? removeMaterial(true) : ""} title="Close"></button>
                    </div>
                    <div className="modal-body py-5">
                        <form className="row needs-validation" method="post" id="submissionForm" noValidate>
                            <div className="row mx-0 mb-4">
                                <label className="col-md-4 col-form-label text-md-end">
                                    {(roleId === 3 || roleId === 4) && opPerform === "mark" && "Mark Update"}
                                    {(roleId === 3 || roleId === 4) && opPerform === "return" && `Return ${_.capitalize(materialType)}`}
                                    {roleId === 6 && `Upload ${_.capitalize(materialType)}`}
                                    <sup className="text-danger fw-bold fs-15px">*</sup>
                                </label>
                                <div className="col-md-8 col-xxl-7">
                                    {(roleId === 3 || roleId === 4) && opPerform === "mark" && <>
                                        <input type="number" min={0} max={totalGrade ? totalGrade : ''} className="form-control" defaultValue={data && data.marks ? data.marks : ""} placeholder="Marks" name="marks" id="marks" onChange={handleInputChange} required />
                                    </>}

                                    {(roleId === 3 || roleId === 4) && opPerform === "return" && <>
                                        <input type="file" className="form-control" name="upload_data" id="upload_data" onChange={handleInputChange} required />
                                    </>}

                                    {roleId === 6 && (
                                        <>
                                            {" "}
                                            <input type="file" className="form-control" name="upload_data" id="upload_data" onChange={handleInputChange} required />
                                            {data && data.pre_uploaded_task && <>
                                                <div className="d-flex pt-2">
                                                    <span className="text-success text-nowrap1 text-truncate">
                                                        {data.pre_uploaded_task}
                                                    </span>
                                                    <button type="button" className="btn py-0 ms-2" title="Remove" onClick={() => removeMaterial(true)}>
                                                        <i className="bi-x-lg ms-auto text-danger"></i>
                                                    </button>
                                                </div>
                                            </>}{" "}
                                        </>
                                    )}

                                    <label className="col-md-4 col-form-label">
                                        {(roleId === 3 || roleId === 4) && opPerform === "mark" && (
                                            <>
                                              {(roleId === 3 || roleId === 4) && opPerform === "mark" && "Total Mark"} : {totalGrade ? totalGrade : ""}
                                                </>
                                        )}
                                    </label> 
                                    <label className="col-md-8 col-form-label">
                                        {(roleId === 3 || roleId === 4) && opPerform === "mark" && (
                                            <>
                                               {(roleId === 3 || roleId === 4) && opPerform === "mark" && "Rewarded Mark"} : {data && data.marks ? data.marks : "NA"}
                                            </>
                                        )}
                                    </label> 
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-sm-8 offset-sm-4">
                                    {(roleId === 3 || roleId === 4) && opPerform === "mark" && <>
                                        <button type="button" className="btn btn-blue minw-120px" onClick={updateMark} title="Submit">
                                            Submit
                                        </button>
                                        {/* <button type="button" className="btn btn-blue minw-120px" title="Submit"  data-bs-dismiss="modal"> Submit</button> */}
                                    </>}
                                    {(roleId === 3 || roleId === 4) && opPerform === "return" && <>
                                        {data && data.pre_return_task && (
                                            <button type="button" className="btn btn-orange minw-120px me-1" onClick={() => downloadReturnTask(action.id)} title="Submit">
                                                Download Return Task
                                            </button>
                                        )}
                                        <button type="button" className="btn btn-blue minw-120px" onClick={() => returnMaterialByTeacher(action.id)} title="Submit">
                                            Submit
                                        </button>
                                    </>}
                                    {roleId === 6 && <>
                                        <button type="button"   disabled={restrictedPdf === 'yes' && fileExtension !== 'pdf' ? true : false} className="btn btn-blue minw-120px" onClick={returnMaterial} title="Submit">
                                            Submit
                                        </button>
                                    </>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaterilDetailsSubmissionModal
