import { Link } from "react-router-dom";

function TeacherCard(props){
    return(
        <>
        <div className="col-md-3 mb-3">
            <div className="course-item h-100">
                <div className="ratio ratio-4x3 bg-light">
                    <img src={props.teacher.user_details.image} className="w-100 h-100 img-cover" alt={props.teacher.name}/>
                </div>
                <span className="course-tag">{props.teacher.subject}</span>
                <div className="p-3">
                    <h6 className="course-heading">{props.teacher.name}</h6>
                    <p className="course-description">
                        {props.teacher.user_details.brief}
                    </p>
                    <div className="pt-2 d-flex justify-content-between align-items-center">
                        <Link to={`/teacher-info/${props.teacher.id}`} className="btn btn-outline-blue" title="View More">View More</Link>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default TeacherCard