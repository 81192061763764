import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { fetchData, validateForm } from "../../components/Helper";
import { useParams } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";

function CourseEdit(){

    const navigate = useNavigate()
    const location = useLocation()
    const courseId = useParams().course_id
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [classCheck, setClassCheck] = useState(false)
    const [courseCheck, setCourseCheck] = useState(false)

    const [teacher, setTeacher] = useState('')
    const [system, setSystem] = useState('')
    const [level, setLevel] = useState('')
    const [board, setBoard] = useState('')
    const [subject, setSubject] = useState('')
    const [image, setImage] = useState()
    const [status, setStatus] = useState()
    const [data, setData] = useState()


    useEffect(() => {

        fetchData(`get-users/4`, 'GET', '', true, false, (res) => {
            setTeacher(res.data)
        }, (err) => {})
        fetchData(`educational-classification-list/system`, 'GET', '', true, false, (res) => {
            setSystem(res.data)
        }, (err) => {})
        fetchData(`educational-classification-list/level`, 'GET', '', true, false, (res) => {
            setLevel(res.data)
        }, (err) => {})
        fetchData(`educational-classification-list/board`, 'GET', '', true, false, (res) => {
            setBoard(res.data)
        }, (err) => {})
        fetchData(`educational-classification-list/subject`, 'GET', '', true, false, (res) => {
            setSubject(res.data)
        }, (err) => {})

        fetchData(`course-update/${courseId}`, 'GET', '', true, false, (res) => {
            setData({
                name: res.data.name,
                teacher: res.data.teacher_id,
                system: res.data.system_cls_id,
                level: res.data.level_cls_id,
                subject: res.data.course_cls_id,
                board: res.data.board_cls_id,
                description: res.data.description,
                appearance_front_end: res.data.appearance,
                class_price: res.data.class_price,
                course_price: res.data.course_price,
                features: res.data.features,
            })
            res.data.class_price > 0 ? setClassCheck(true) : setClassCheck(false)
            res.data.course_price > 0 ? setCourseCheck(true) : setCourseCheck(false)
            setPaymentDate(new Date(res.data.course_due_date))
            setStatus(res.data.status)
            setImage(res.data.image)
        }, (err) => {})
    }, [courseId])

    const handleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    const submitForm = (e) => {
        if(validateForm(e)) { 
            saveData(status) 
        }
    }

    const submitDraftForm = (e) => {
        if(validateForm(e)) { 
            saveData('draft') 
        }
    }

    const submitPublishForm = (e) => {
        if(validateForm(e)) { 
            saveData('active') 
        }
    }
    const saveData = (status) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            formData.append(key, value)
          }
        formData.append('poster', image)
        formData.append('status', status)
        formData.append('class', classCheck)
        formData.append('course', courseCheck)
        formData.append('pathname', location.pathname)
        formData.append('payment_date', paymentDate.toISOString())

        fetchData(`course-update/${courseId}`, 'POST', formData, true, true, (res) => {
            if(res.status){
                navigate(`/course/list`)
            }
        }, (err) => {})
    }
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach | Course Edit</title>
        </Helmet>
        { data ? 
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Course Edit</h5>
                        </div>
                        <div className="col-auto mb-2">
                        <Link to={`/course/list`} className="btn btn-blue mb-1 me-1" title="Course List">Course List</Link>
                        <Link to={`/course/list/draft`} className="btn btn-warning px-3 mb-1" title="Draft Course">Drafts Course</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body p-sm-4 p-xl-5">
                                    <form action="#" method="post" className="needs-validation" noValidate>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="name" className="col-sm-3 col-xxl-2 col-form-label">Course Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="name" name="name" defaultValue={data ? data.name : ''} required onChange={handleInputChange}/>
                                            </div>
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="poster" className="col-sm-3 col-xxl-2 col-form-label">Course Poster</label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="file" className="form-control" id="poster" name="poster" defaultValue="" onChange={(e) => setImage(e.target.files[0])} accept="image/png, image/jpeg, image/jpg" />
                                            </div>
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="teacher" className="col-sm-3 col-xxl-2 col-form-label">Assign to Teacher<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="teacher" id="teacher" required onChange={handleInputChange}>
                                                    <option value=''>- select -</option>
                                                    { teacher ? teacher.map((teacher_detail) => {
                                                        return (<option key={teacher_detail.id} value={teacher_detail.id} selected={data.teacher === teacher_detail.id ? true : false}>{teacher_detail.name}</option>)
                                                    }) : '' }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="description" className="col-sm-3 col-xxl-2 col-form-label">Description<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <textarea rows="4" className="form-control" id="description" name="description" spellCheck="false" required defaultValue={data ? data.description : ''} onChange={handleInputChange} />
                                            </div>
                                        </div>


                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="pricing" className="col-sm-3 col-xxl-2 col-form-label">Pricing:</label>
                                            <div className="col-sm-9 col-xxl-10">
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <label className="form-check minw-120px me-3">
                                                        <input className="form-check-input" type="checkbox" defaultValue="" defaultChecked={classCheck} onChange={() => setClassCheck(!classCheck)}/>
                                                        <div className="form-check-label" htmlFor="class">
                                                            Class Price{classCheck ? <sup className='text-danger fw-bold fs-15px'>*</sup> : ''}
                                                        </div>
                                                    </label>
                                                    <input type="text" className="form-control w-auto" placeholder="class price" id="class_price" name="class_price" defaultValue={data ? data.class_price : ''} onChange={handleInputChange} required={classCheck} disabled={!classCheck}/>
                                                </div>
                                                <div className="d-flex flex-wrap align-items-center mt-2">
                                                    <label className="form-check minw-120px me-3">
                                                        <input className="form-check-input" type="checkbox" defaultValue="" defaultChecked={courseCheck}  onChange={() => setCourseCheck(!courseCheck)}/>
                                                        <div className="form-check-label" htmlFor="course">
                                                            Course Price{courseCheck ? <sup className='text-danger fw-bold fs-15px'>*</sup> : ''}
                                                        </div>
                                                    </label>
                                                    <div className="d-flex flex-wrap">
                                                        <input type="text" className="form-control w-auto my-2px me-2" placeholder="course price" id="course_price" name="course_price" defaultValue={data ? data.course_price : ''} onChange={handleInputChange} required={courseCheck} disabled={!courseCheck}/>
                                                        <div className="my-2px">
                                                            <DatePicker 
                                                                selected={courseCheck ? paymentDate : null} 
                                                                onChange={(date) => setPaymentDate(date)} 
                                                                className="form-control"
                                                                minDate={new Date()}
                                                                timeInputLabel="Time:"
                                                                dateFormat="MMM dd, yyyy h:mm aa"
                                                                showTimeInput
                                                                onChangeRaw={(e) => e.preventDefault()}
                                                                disabled={!courseCheck}
                                                            />
                                                            <div className="position-absolute fs-13px b-0 text-orange">Due of 2nd payment date.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="system" className="col-sm-3 col-xxl-2 col-form-label">Assign to System<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="system" id="system" onChange={handleInputChange} required>
                                                    <option value=''>- select -</option>
                                                    { system ? system.map((system_detail) => {
                                                            return (<option key={system_detail.id} value={system_detail.id} selected={data.system === system_detail.id ? true : false}>{system_detail.name}</option>)
                                                    }) : '' }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="level" className="col-sm-3 col-xxl-2 col-form-label">Assign to Level<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="level" id="level" onChange={handleInputChange} required>
                                                    <option value=''>- select -</option>
                                                    { level ? level.map((level_detail) => {
                                                            return (<option key={level_detail.id} value={level_detail.id} selected={data.level === level_detail.id ? true : false}>{level_detail.name}</option>)
                                                    }) : '' }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="subject" className="col-sm-3 col-xxl-2 col-form-label">Assign to Subject<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="subject" id="subject" onChange={handleInputChange} required>
                                                    <option value=''>- select -</option>
                                                    { subject ? subject.map((subject_detail) => {
                                                            return (<option key={subject_detail.id} value={subject_detail.id} selected={data.subject === subject_detail.id ? true : false}>{subject_detail.name}</option>)
                                                    }) : '' }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="board" className="col-sm-3 col-xxl-2 col-form-label">Assign to Board<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="board" id="board" onChange={handleInputChange} required>
                                                    <option value=''>- select -</option>
                                                    { board ? board.map((board_detail) => {
                                                            return (<option key={board_detail.id} value={board_detail.id} selected={data.board === board_detail.id ? true : false}>{board_detail.name}</option>)
                                                    }) : '' }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="frontend" className="col-sm-3 col-xxl-2 col-form-label">Appearance on front end<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="form-check form-check-inline mt-2">
                                                    <input className="form-check-input" type="radio" name="appearance_front_end" value="yes" id="yes" defaultChecked={data && data.appearance_front_end === "yes" ? true : false} onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="yes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="appearance_front_end" value="no" id="no" defaultChecked={data && data.appearance_front_end === "no" ? true : false} onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="no">
                                                        No
                                                    </label>
                                                </div>  
                                            </div>                                          
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="features" className="col-sm-3 col-xxl-2 col-form-label">Features<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <div className="form-check form-check-inline mt-2">
                                                    <input className="form-check-input" type="radio" name="features" value="yes" id="yes" defaultChecked={data && data.features === "yes" ? true : false} onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="yes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" name="features" value="no" id="no" defaultChecked={data && data.features === "no" ? true : false} onChange={handleInputChange}/>
                                                    <label className="form-check-label" htmlFor="no">
                                                        No
                                                    </label>
                                                </div>  
                                            </div>                                          
                                        </div>
                                        {status !== 'draft' ?
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="status" className="col-sm-3 col-xxl-2 col-form-label">Status<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <select className="form-select" name="status" id="status" onChange={(e) => setStatus(e.target.value)}>
                                                    <option value="active" selected={status === 'active' ? true : false}>Active</option>
                                                    <option value="inactive" selected={status === 'inactive' ? true : false}>Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                        : '' }
                                        <div className="row mx-0">
                                            <div className="col-sm-9 offset-sm-3 offset-xxl-2">
                                                { status === 'draft' ? <>
                                                <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={submitPublishForm} title="Publish">Publish</button>
                                                <button type="button" className="btn btn-outline-success" onClick={submitDraftForm} title="Save Draft">Save Draft</button>
                                                </> : <>
                                                <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={submitForm} title="Update">Update</button>
                                                </> }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            : '' }
        </>
    )
}

export default CourseEdit