import React, { useState, useEffect, useContext } from 'react'
import { isEmpty } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import Context from '../../components/Context';
import Pagination from '../../components/Pagination';
import { fetchData, showAlert } from '../../components/Helper';

function AllCourse(props) {
    const location = useLocation()
    const navigate  = useNavigate()
    const [context] = useContext(Context)
    const [subject, setSubject] = useState('');
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        let group_title = '';
        if(location.pathname.match('/my-classes')){
            group_title = 'My Classes';
        }else if(location.pathname.match('/my-subjects') || location.pathname.match('/timing')){
            group_title = 'My Classes';
        }else if(location.pathname.match('/my-students')){
            group_title = 'My Students';
        }else if(location.pathname.match('/library')){
            group_title = 'Library';
        }else if(location.pathname.match('/recordings') || location.pathname.match('/recording')){
            group_title = 'Recordings';
        }else if(location.pathname.match('/assignments') || location.pathname.match('/assignment')){
            group_title = 'Assignments';
        }else if(location.pathname.match('/quizzes') || location.pathname.match('/quizz')){
            group_title = 'Quizzes';
        }else if(location.pathname.match('/notes') || location.pathname.match('/note')){
            group_title = 'Notes';
        }else if(location.pathname.match('/performance')){
            group_title = 'Performance';
        }    

        document.getElementById('group-title').innerHTML = group_title;
        document.title = 'WeTeach | '+group_title;

        if (context && context.auth) {
            let userId = context.auth.role_id === 5 || context.auth.role_id === 6 ? `student_id=${props.student_id}` : `teacher_id=${props.student_id}`
            fetchData(`subject-list?${userId}&page=${currentPage}`, 'GET', '', true, false, (res) => {
                setSubject(res.data)
            }, (err) => {});
        }
    }, [context, currentPage, props.student_id, location.pathname, location.key, navigate]) 
    
    const handlePageClick = (data) => {
        setCurrentPage(data.selected >= 0 ? data.selected + 1 : 0)
	}
    
    const checkAnyCourseAttend = (id, status) => {
        if(context.auth.role_id === 5 || context.auth.role_id === 6){
            if(status === 'inactive'){
                let msg = {message: `Dear student! you have been withdrawn from this course. If you want to join this course, please contact to the administrator.`,status:false}
                showAlert(msg)
            }else{
                if((props.type === 'assignment' || props.type === 'quizz' || props.type === 'library')){
                    fetchData(`check-any-class-attend?course_id=${id}&user_id=${props.student_id}&type=${props.type}`, 'GET', '', true, false, (res) => {
                        if(res.status){
                            props.getCourseId(id)
                            navigate({ pathname: `/${props.type}/details`, state: {user_id: props.student_id, course_id: id} })
                        }
                    }, (err) => {});
                }else{
                    props.getCourseId(id)
                    navigate({ pathname: `/${props.type}/details`, state: {user_id: props.student_id, course_id: id} })
                }
            }
        }else{
            props.getCourseId(id)
            navigate({ pathname: `/${props.type}/details`, state: {user_id: props.student_id, course_id: id} })
        }
	}

    return ( 
        <>
            <div className="card-body pb-0">
                {
                    subject ? isEmpty(subject.data) ? <div className="text-danger text-center pb-3">There is no record in {props.type ? props.type : ''}.</div> : subject.data.map((item, index) => {
                        return (<>
                            <div key={index} onClick={() => checkAnyCourseAttend(item.id, item.status)} className="text-decoration-none border rounded px-3 py-2 w-100 link-effect fw-medium d-flex align-items-center bg-light mb-2" role="button">
                                {`${item.display_name}`} 
                                <i className="bi-arrow-right-circle-fill bi-3x ms-auto"></i>
                            </div></>
                        )
                    }) : ''
                }
                <Pagination paginate_data={subject} side='end' onPageChange={handlePageClick}/>
            </div>
        </>
    )
}

export default AllCourse
