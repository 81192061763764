import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData, validateForm } from "./Helper";
import Context from "./Context";

function ChangePassword(){
    const navigate = useNavigate()
    const [context] = useContext(Context)

    const [data, setData] = useState({
        current_password: "",
        new_password: "",
        confirm_password: "",
    })
    
    const handeleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]:e.target.value,
        }))
    }
    const handleSubmitForm = (e) => {
		e.preventDefault();
        if(validateForm(e)){
            fetchData('change-password', 'POST', data, true, false, (res) => {
                if(res.status) {
                    setData({
                        current_password: "",
                        new_password: "",
                        confirm_password: "",
                    })

                    setTimeout(() => {
                        if(context.auth.role_id === 1 || context.auth.role_id === 2){
                            navigate('/dashboard')
                        }else{
                            navigate('/my-profile')
                        }
                    }, 500)
                    
                }
            }, (err) => {
            })
        }
	}
    return(
        <>
            <form action="#" method="post" className="p-4 p-sm-5 needs-validation" id="passwordchangeForm" noValidate>
                <div className="mb-2 mb-sm-4 row mx-0">
                    <label htmlFor="password" className="col-sm-3 col-xxl-2 col-form-label">Current Password</label>
                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                        <input type="password" className="form-control" id="current_password" onChange={handeleInputChange} name="current_password" required />
                    </div>
                </div>
                <div className="mb-2 mb-sm-4 row mx-0">
                    <label htmlFor="password" className="col-sm-3 col-xxl-2 col-form-label">New Password</label>
                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                        <input type="password" className="form-control" id="new_password" onChange={handeleInputChange} name="new_password" required />
                    </div>
                </div>
                <div className="mb-2 mb-sm-4 row mx-0">
                    <label htmlFor="confirm_password" className="col-sm-3 col-xxl-2 col-form-label">Confirm Password</label>
                    <div className="col-sm-9 col-lg-6 col-xxl-5">
                        <input type="password" className="form-control" id="confirm_password" password="confirm_password" onChange={handeleInputChange} name="confirm_password" required />
                    </div>
                </div>
                <div className="row mx-0">
                    <div className="col-sm-9 offset-sm-3 offset-xxl-2">
                        <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={handleSubmitForm} title="Change">Change</button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default ChangePassword