import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
import { useEffect, useState} from "react";
import { fetchData} from "../../components/Helper";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

function CourseCarousel() {
    const [course, setCourse] = useState(''); 
    const navigate = useNavigate();

  useEffect(() => {
    fetchData('courses/yes', 'GET', '', false, false, (res) => {
        if(res.status && res.data){
            setCourse(res.data)
        }
    }, (err) => {})
}, []);

const handleJoinCourse = (id) => {
    navigate(`/course/${id}/join`) 
}


  const responsiveOptions = {
    0: {
      nav: true,         
      dots: false,
      items: 1, // Number of items to show at 0px screen width
    },
    600: {
      nav: true,         
      dots: false,
      items: 2, // Number of items to show at 600px screen width
    },
    1000: {
      nav: true,         
      dots: false,
      items: 4, 
    },
  };


  return (  
    <div>  
      <div className='container-fluid'>            
        {course.length > 0 && (
          <OwlCarousel items={course.length} className="owl-carousel owl-arrow-dots" nav margin={8} responsive={responsiveOptions}>
            {course.map((course_details, i) => (
               <div className="item" key ={i}> 
                        <div className="course-item">
                            <div className="ratio ratio-4x3 bg-light">
                                <img src={course_details.course_image} className="w-100 h-100 img-cover" alt="weteach" />
                            </div>
                            <span className="course-tag">{course_details.subject_name}</span>
                            <div className="course-avatar d-flex justify-content-between align-items-center">
                            {course_details.teacher_image ?  <img src={course_details.teacher_image} className="mmw-50px" alt="weteach" /> : ''}
                                <span>{(course_details.class_price ===  'EGP 0.00' || course_details.class_price ===  'EGP 0') ? course_details.course_price : course_details.class_price }</span>
                            </div>
                            <div className="p-3">
                                <h6 className="course-heading">{course_details.name}</h6>
                                <p className="course-description minh-72px">
                                    {course_details.description}
                                </p>
                                <div className="pt-3 d-flex justify-content-between align-items-center">
                                { course_details.teacher_name ? 
                                    <Link to={`/teacher-info/${course_details.teacher_id}`} className="fs-14px mb-0 text-muted text-hoverorange text-truncate" title={`By ${course_details.teacher_name }`}>
                                        By {course_details.teacher_name }
                                    </Link> 
                                    : ''
                                }
                                    <button onClick={() => handleJoinCourse(course_details.id)} className="btn btn-outline-blue btn-sm fw-medium text-nowrap" title="Join Course">Join Course</button>
                                </div>
                            </div>
                        </div> 
                </div>
            ))}
          </OwlCarousel>
        )}
      </div>  
    </div>  
  );
}

export default CourseCarousel;
