import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"

function CourseCard(props){ 
    const navigate = useNavigate();

    const handleJoinCourse = (id) => {
        navigate(`/course/${id}/join`) 
    }

    return(
        <>
        { props.course_details ? props.course_details.length === 0 ? 
            <h4 className="text-center w-100 rounded-10 py-5 text-danger">
                There is no matching course.
            </h4> : props.course_details.map((course_details) =>{
            return( props.hide && course_details.appearance === 'no' ? '' :
                <div className="item" key={course_details.id}> 
                    <div className="course-item">
                        <div className="ratio ratio-4x3 bg-light">
                            <img src={course_details.course_image} className="w-100 h-100 img-cover" alt="weteach"/>
                        </div>
                        <span className="course-tag">{course_details.subject_name}</span>
                        <div className="course-avatar d-flex justify-content-between align-items-center">
                           {course_details.teacher_image ?  <img src={course_details.teacher_image} className="mmw-50px"  alt="weteach" /> : ''}
                            <span>{(course_details.class_price ===  'EGP 0.00' || course_details.class_price ===  'EGP 0') ? course_details.course_price : course_details.class_price }</span>
                        </div>
                        <div className="p-4">
                            <h6 className="course-heading">{course_details.name}</h6>
                            <p className="course-description minh-72px">
                                {course_details.description}
                            </p>
                            <div className="pt-3 d-flex justify-content-between align-items-center">
                               { course_details.teacher_name ? 
                                    <Link to={`/teacher-info/${course_details.teacher_id}`} className="fs-14px mb-0 text-muted text-hoverorange text-truncate" title={`By ${course_details.teacher_name }`}>
                                        By {course_details.teacher_name }
                                    </Link>                                  : ''
                               }
                               { props.joinDisabled ? '' : <button onClick={() => handleJoinCourse(course_details.id)} className="btn btn-outline-blue btn-sm fw-medium text-nowrap" title="Join Course">Join Course</button> }
                            </div>
                        </div>
                    </div> 
                </div>
            )
        }) 
        :
        Array.from(Array(8)).map((c, index) => 
        <div className="item" key={index}> 
            <div className="course-item placeholder-wave">
                <div className="ratio ratio-4x3 bg-light">
                    <div className="w-100 h-100 img-cover placeholder"></div>
                </div>
                <span className="course-tag placeholder w-25"></span>
                <div className="course-avatar d-flex justify-content-between align-items-center">
                    <div className="mmw-50px placeholder"></div>
                </div>
                <div className="p-3">
                    <h6 className="course-heading placeholder w-50">&nbsp;</h6>
                    <p className="course-description placeholder"></p>
                    <p className="course-description placeholder w-25"></p>
                    <div className="pt-3 d-flex justify-content-between align-items-center ">
                        <span className="fs-14px mb-0 text-muted text-hoverorange text-truncate placeholder w-25"></span> 
                        <button className="btn btn-outline-blue btn-sm fw-medium disable placeholder w-25"></button>
                    </div>
                </div>
            </div> 
        </div> )
        }
        </>
    )
}

export default CourseCard
