import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useNavigate } from "react-router-dom";
import { reloadUrlDataTable } from "../../components/Datatables";
import { fetchData } from "../../components/Helper";
let componentStatus='active';
const useHook =  () =>{

    const [loadComponent, setLoadComponent] = useState("active");
    const navigate = useNavigate();

    const [dt, setDt] = useState({
        dt_url: "get-schedule?status="+loadComponent,
        dt_load_component:loadComponent,
        dt_name: "schedule_"+loadComponent,
        dt_column: [
            {data: "id", name: "id", title: "#ID"},
            {data: "event_name", name: "event_name", title: "Course/Event Name"},
            {data: "event_description", name: "event_description", title: "Course/Event Description"},
            {data: "schedule_time", name: "schedule_time", title: "Schedule Time"},
            {data: "type", name: "courses.name", title: "Type"},
            {data: "status", name: "status", title: "Status"},
            {
                data: "id",
                title: "Action",
                sortable: false,
                searchable: false,
                class: "text-center text-nowrap",
                width: 160,
            },
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>{rowData.event_name ? rowData.event_name : rowData.course_name}</>);
                },
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <>{rowData.event_description ? rowData.event_description : rowData.course_description}</>
                    );
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <>
                            {rowData.status === "active" ? <span className="text-green fw-medium">Active</span> : ""}
                            {rowData.status === "expired" ? (
                                <span className="text-warning fw-medium">Expired</span>
                            ) : (
                                ""
                            )}
                            {rowData.status === "inactive" ? (
                                <span className="text-danger fw-medium">Inactive</span>
                            ) : (
                                ""
                            )}
                        </>
                    );
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <>
                            {rowData.event_name ? (
                                <span className="fw-medium">Event</span>
                            ) : (
                                <span className="fw-medium">Course</span>
                            )}
                        </>
                    );
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(
                        <div className="text-end">
                            {rowData.status === "inactive" ? (
                                <button
                                    type="button"
                                    className="btn btn-success py-2px m-2px w-100px"
                                    title="Activate"
                                    onClick={() => handleScheduleStatus("active", rowData.id)}
                                >
                                    Activate
                                </button>
                            ) : (
                                ""
                            )}

                            {rowData.status === "active" ? (
                                <button
                                    type="button"
                                    className="btn btn-danger py-2px m-2px w-100px"
                                    title="Deactivate"
                                    onClick={() => {  handleScheduleStatus("inactive", rowData.id,)}}
                                >
                                    Deactivate
                                </button>
                            ) : (
                                ""
                            )}
                            {rowData.status === "expired" ? <button
                                        type="button"
                                        className="btn btn-danger py-2px m-2px"
                                        title="Delete"
                                        onClick={() => handleScheduleStatus("delete", rowData.id)}
                                    >
                                        Delete
                                    </button> : ""}

                            {rowData.status === "inactive" || rowData.status === "active" ? (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-dark py-2px m-2px"
                                        title="Edit"
                                        onClick={() => editSchedule(rowData.id)}
                                    >
                                        Edit
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-danger py-2px m-2px"
                                        title="Delete"
                                        onClick={() => handleScheduleStatus("delete", rowData.id)}
                                    >
                                        Delete
                                    </button>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                },
            },
        ],
    });
    const [show,setShow] = useState(true)
    const handleScheduleTab = async(type) => {
        setDt((state) => ({...state, dt_name: "schedule_" + type, dt_url: "get-schedule?status=" + type,dt_load_component:type}))
        setLoadComponent(type);
        componentStatus=type;
    };
    const handleScheduleStatus = (status, id) => {
        fetchData(
            "schedule/change-status",
            "POST",
            {status: status, id: id},
            true,
            false,
            (res) => {
                setShow(false)
                setDt((state) => ({...state, dt_name: "schedule_" + componentStatus, dt_url: "get-schedule?status=" + componentStatus,dt_load_component:componentStatus}))
                reloadUrlDataTable(dt, "get-schedule?status=" + componentStatus);
            },
            (err) => {}
        );
    };

    const editSchedule = (schedule_id) => {
        navigate(`/schedule/edit/${schedule_id}`);
    };
    useEffect(() => {
            reloadUrlDataTable(dt, "get-schedule?status=" + loadComponent);
    }, [dt,loadComponent]);

    return{
loadComponent, setLoadComponent,dt,editSchedule,reloadUrlDataTable,handleScheduleTab,handleScheduleStatus,show
    }
}
export default useHook