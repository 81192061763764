import $ from 'jquery';
import jsZip from 'jszip';
import '../css/datatable-custom.css';
import 'pdfmake';
import 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-rowreorder';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.print.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import moment from 'moment';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { fetchData } from '../components/Helper';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.JSZip = jsZip;

const API_URL = process.env.REACT_APP_API_URL

export const initDataTable = (dt) => {
    if (typeof $ !== "undefined" && $.fn.dataTable) {
        var all_settings = $($.fn.dataTable.tables()).DataTable().settings();
        for (var i = 0, settings; (settings = all_settings[i]); ++i) {
            if (settings.jqXHR)
                settings.jqXHR.abort();
        }
    }
    const exportButons = [{
        extend: "collection",
        className: 'btn btn-sm me-0 float-end export-btn border rounded',
        text: "Table Controls &nbsp;",
        buttons: [
            {
                extend: "excelHtml5",
                title: dt && dt.dt_export_name ? dt.dt_export_name : window.document.title,
                className: 'export-btn',
                filename: 'export - ' + moment().format("DD-MMM-YYYY"),
                exportOptions: {
                    columns: "thead th:not(.noExport)",
                    modifier: {
                        order: 'current',
                        page: 'all',
                        search: 'none',
                        selected: null
                    }
                }
            },
            {
                extend: "csvHtml5",
                title: dt && dt.dt_export_name ? dt.dt_export_name : window.document.title,
                className: 'export-btn',
                filename: 'export - ' + moment().format("DD-MMM-YYYY"),
                exportOptions: {
                    columns: "thead th:not(.noExport)",
                    modifier: {
                        order: 'current',
                        page: 'all',
                        search: 'none',
                        selected: null
                    }
                }
            },
            {
                extend: 'pdfHtml5',
                title: dt && dt.dt_export_name ? dt.dt_export_name : window.document.title,
                filename: 'export - ' + moment().format("DD-MMM-YYYY"),
                orientation: 'landscape',
                pageSize: 'LEGAL',
                className: 'export-btn',
                customize: function (doc) {
                    doc.defaultStyle.fontSize = 13;
                    doc.styles.tableHeader.fontSize = 13;
                    var count = table.columns(':visible').count();
                    if (count < 9) {
                        doc.pageSize = 'LEGAL';
                    } else if (count >= 9 && count <= 10) {
                        doc.pageSize = 'A4';
                    } else if (count >= 11 && count <= 15) {
                        doc.pageSize = 'A3';
                    } else if (count >= 16 && count <= 20) {
                        doc.pageSize = 'A2';
                    } else if (count >= 21 && count <= 30) {
                        doc.pageSize = 'A1';
                    } else {
                        doc.pageSize = 'A0';
                    }
                },
                exportOptions: {
                    columns: "thead th:not(.noExport)",
                    modifier: {
                        order: 'current',
                        page: 'all',
                        search: 'none',
                        selected: null
                    }
                }
            },
            {
                extend: 'print',
                title: dt && dt.dt_export_name ? dt.dt_export_name : window.document.title,
                text: 'Print',
                className: 'export-btn',
                key: {
                    key: 'p',
                    altKey: true
                },
                exportOptions: {
                    columns: "thead th:not(.noExport)",
                    modifier: {
                        order: 'current',
                        page: 'all',
                        search: 'none',
                        selected: null
                    }
                }
            },
            {
                extend: 'copy',
                title: dt && dt.dt_export_name ? dt.dt_export_name : window.document.title,
                text: 'Copy',
                className: 'export-btn ',
                key: {
                    key: 'c',
                    altKey: true
                },
                exportOptions: {
                    columns: "thead th:not(.noExport)",
                    modifier: {
                        order: 'current',
                        page: 'all',
                        search: 'none',
                        selected: null
                    }
                }
            },
            {
                extend: "colvis",
                text: 'Column Visibility &nbsp;',
                collectionLayout: "fixed two-column",
                collectionTitle: "Select Columns to Display",
                postfixButtons: ["colvisRestore"],
                columnText: function (dt, idx, title) {
                    return idx + 1 + ": " + title;
                }
            }
        ],
        select: true
    }];

    const table = $('#wt_datatable_' + dt.dt_name).DataTable({
        dom: "<'row clearfix mx-0 mb-2'B>" +
            "<'row clearfix mx-0'<'col col-sm-6 col-lg-2 px-0 text-center text-sm-start float-start'l><'dt-custom-filter col col-sm-6 col-lg-8 px-0 d-flex flex-row-reverse text-center text-sm-end'<'#dt-custom-button.ms-2'>><'col-md-12 col-lg-2 px-0 ps-2 ps-ms-0 text-center text-lg-end'f>>" +
            "<'row clearfix mx-0'<'col-sm-12 px-0'<'table-responsive position-relative my-1' tr>>>" +
            "<'row clearfix mx-0 mt-3'<'col-md-12 col-lg-5 px-0 text-center text-lg-start'i><'col-md-12 col-lg-7 px-0 text-center text-lg-end'p>>",
        language: {
            searchPlaceholder: "Search...",
            processing: "Loading...",
            'loadingRecords': '&nbsp;',
        },
        lengthMenu: [[10, 15, 25, 50, 100, 250, 500, -1], [10, 15, 25, 50, 100, 250, 500, "All"]],
        processing: true,
        serverSide: true,
        orderable: true,
        paging: dt.dt_paging,
        ajax: {
            url: API_URL + dt.dt_url,
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem("wt-token")
            },
            data: (d) => {
                d.s_filter = $('input[name=dt_filter_status]:checked').val();
            },
        },
        columns: dt.dt_column,
        columnDefs: dt.dt_column_defs,
        order: dt.dt_order ? dt.dt_order : [[0, 'desc']],
        initComplete: dt.dt_filter,
        drawCallback: (settings) => {
            if (settings.json.message === "Unauthenticated." || settings.json.type === "block_user") {
                if (settings.json.type === "block_user") localStorage.setItem("block_user", JSON.stringify(settings.json))
                localStorage.removeItem("wt-token");
                window.location.href = '/'
            }
        },
        createdRow: dt.dt_createdRow,
        buttons: dt.dt_export ? exportButons : [],
        rowReorder: dt.dt_rowReorder ? {
            selector: '.drag',
            dataSrc: dt.dt_rowReorder.order_by ?? 'order_id',
        } : false,
    });
    table.buttons().container().appendTo($('.exportbuttons'))

    var headers = table.columns().header().toArray();
    $(headers).on('click', function (e) {
        table.search('').draw();
    });


    $('#wt_datatable_' + dt.dt_name).DataTable().on('row-reorder', function (e, diff) {
        var data = []
        diff.forEach(element => {
            let id = $('#wt_datatable_' + dt.dt_name).DataTable().row(element.node).data().id
            data.push({ id, value: element.newData });
        });

        if (diff.length > 0) {
            fetchData(dt.dt_rowReorder.url, 'POST', { data }, true, false, (res) => {
                reloadDataTable(dt)
            }, false, false, false)
        }
    });
}



export const reloadDataTable = (dt) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_' + dt.dt_name)) {

        $('#wt_datatable_' + dt.dt_name).DataTable().clear().draw(null, false)
    } else {
        initDataTable(dt)
    }
}

export const redrawDataTable = (dt) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_' + dt.dt_name)) {
        $('#wt_datatable_' + dt.dt_name).DataTable().clear().draw(null, false)
    } else {
        initDataTable(dt)
    }
}

export const destroyDataTable = (dt) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_' + dt.dt_name)) {
        $('#wt_datatable_' + dt.dt_name).DataTable().destroy()
    }
}

export const reloadUrlDataTable = (dt, url) => {
    if ($.fn.DataTable.isDataTable('#wt_datatable_' + dt.dt_name) && url) {
        $('#wt_datatable_' + dt.dt_name).DataTable().clear().ajax.url(API_URL + url).load()
    } else {
        initDataTable(dt)
    }
}

function DataTables(props) {
    return (
        <>
            <table id={`wt_datatable_${props.dt_name}`} className="table table-hover align-middle w-100">
                <thead className="bg-light "></thead>
            </table>
        </>
    )
}

export default DataTables