import {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { fetchData, validateForm, intlTel_phone, validatePhone, scrollbarSetToTop } from "../components/Helper";

const ContactUs = () => {
    const [data, setData] = useState({ name: "", email: "", message: "", phone: "" })
    const [intlTel, setIntlTel] = useState()

    useEffect(() => {
        scrollbarSetToTop();
        setIntlTel(intlTel_phone('#phone', '', 'us', true)) 
     }, []);
     
    const handeleInputChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.name]:e.target.value,
        }))
    }

    const handleSubmitForm = (e) => {
		e.preventDefault();
        if(validateForm(e) && validatePhone(intlTel, '#phone')){
            var contactForm = document.getElementById('contactForm')
            
            let contact_data = {...data, 'phone': intlTel.getNumber()}

            fetchData('contact-add', 'POST', contact_data, false, false, (res) => {
                if(res.status) {
                    setData({
                        name: "",
                        email: "",
                        message: "",
                        phone: ""
                    })
                    contactForm.reset()
                    contactForm.classList.remove('was-validated')
                }
            }, (err) => {})
        }
	}
    return(
        <section className="login-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Contact Us</title>
            </Helmet>
            <div className="container">
                <div className="row g-0 rounded-10 shadow overflow-hidden">
                    <div className="col-md-6 col-lg-5 bg-white">
                        <form id="contactForm" className="p-4 p-sm-5 needs-validation" noValidate>
                            <h4 className="heading-lines mb-4 mt-0">Contact Us</h4>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" name="name" id="name" placeholder="Name" onChange={ handeleInputChange } required />
                                <label htmlFor="studentname">Name*</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" name="email" id="email" placeholder="name@example.com" onChange={ handeleInputChange } required />
                                <label htmlFor="floatingInput">Email address*</label>
                            </div>
                            <div className="form mb-3">
                                <input type="tel" className="form-control" id="phone" autoComplete="off" name="phone" data-intl-tel-input-id="0" style={{'height': '58px'}} required />
                            </div>
                            <div className="form-floating mb-3">
                                <textarea className="form-control" name="message" id="message" rows="6" placeholder="Message" style={{'height': '110px'}} onChange={ handeleInputChange } required></textarea>
                                <label htmlFor="message">Message*</label>
                            </div>
                            <div className="mb-4">
                                <button type="submit" className="btn btn-blue btn-lg w-100" onClick={handleSubmitForm} title='SUBMIT'>SUBMIT</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 col-lg-7">
                        <img src="images/contact-us.svg" className="w-100" alt="weteach"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ContactUs