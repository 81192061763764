import React from "react";
import $ from "jquery";
import {fetchData, validateForm} from "../../components/Helper";
import {Modal, Button} from "react-bootstrap";
import {useState} from "react";
import {reloadDataTable} from "../Datatables";
import SendReminderButton from "./SendReminderButton";
import { useParams } from "react-router-dom";

export default function InviteStudent(props) {
    const courseId = useParams().course_id;
    const [show, setShow] = useState(false);
    const [showReminder, setShowReminder] = useState(false);
    const [loading, setLoading] = useState(false);
    const [studentData, setStudentData] = useState({course_id: courseId, source:'Source'});
    const handleInputChange = (e) => {
        setShowReminder(false)
        let id = document.getElementById(e.target.name);
        if (id) {
            id.classList.remove("is-invalid");
            $(e.target.name).siblings(".invalid-feedback").css("display", "none");
        }
        setStudentData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleClose = () => setShow(false);
    const handleSaveStudent = (e) => {
        e.preventDefault();
        if (validateForm(e, "invite_student")) {
            setLoading(true);
            let student_data = studentData;
            fetchData(
                "student-invite",
                "POST",
                student_data,
                false,
                false,
                (res) => {
                    setLoading(false);
                    if (res.status) {
                        setShow(false);
                        reloadDataTable(props.enroll_student_dt);
                    } else {
                        if(res.data){
                            setShowReminder(true)
                            setStudentData({...studentData,studentid:res.data.id})
                        }
                    }
                },
                (err) => {}
            );
        }
    };
    return (
        <>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-1">
                <div className="text-orange mb-1"></div>
                <div className="">
                    <button type="button" className="btn btn-success px-3 mb-1 me-2" onClick={() => setShow(true)}>
                        Invite
                    </button>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title> Invite Student</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="#" method="post" className="needs-validation" id="invite_student" noValidate>
                        <div className="col-sm-12 col-md-12 mb-3">
                            <label htmlFor="studentname" className="mb-2">
                                Student Name<span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                // pattern="[a-zA-Z]+ [a-zA-Z]+$"
                                pattern="([a-zA-Z]+ [a-zA-Z]+( [a-zA-Z]+)*)+"
                                name="student_name"
                                id="student_name"
                                onChange={handleInputChange}
                                placeholder="Student Name*"
                                required
                            />
                            <small className="text-danger validation-font ">
                                <b>Note: </b> First Name & Last Name is required
                            </small>
                        </div>
                        <div className="col-sm-12 col-md-12 mb-3">
                            <label htmlFor="studentemail" className="mb-2">
                                Student Email<span className="text-danger">*</span>
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                name="student_email"
                                id="student_email"
                                placeholder="Student Email"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="col-sm-12 col-md-12 mb-3">
                            <label htmlFor="source" className="mb-2">
                                Source<span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="source"
                                id="source"
                                defaultValue="Source"
                                placeholder="Source"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            {loading ? (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-blue w-120px px-4 me-2 float-right"
                                        title="Loading..."
                                        disabled
                                    >
                                        Loading...
                                    </button>
                                </>
                            ) : showReminder ? (
                                <>
                                <SendReminderButton studentid={studentData.studentid} course_id={studentData.course_id} modal={setShow}/>
                                </>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-blue w-120px px-4 me-2 float-right"
                                        onClick={handleSaveStudent}
                                        title="Create"
                                    >
                                        Save
                                    </button>
                                </>
                            )}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
