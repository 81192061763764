import { createRoot } from "react-dom/client";
import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import Datatables, { reloadUrlDataTable } from '../../components/Datatables';
import { fetchData, validateForm, ModalSection, initialFormState } from '../../components/Helper';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function  Manufi(props) {
    const location = useLocation();
    const listType = useParams().list_type
    const [draftList, setDraftList] = useState(false)
    const [recipientList, setRecipientList] = useState(false)
    const [courseList, setCourseList] = useState('')
    const [listSelect, setListSelect] = useState(false)
    const [courseSelect, setCourseSelect] = useState(false)
    const [scheduleTimeEnable, setScheduleTimeEnable] = useState(false)
    const [scheduleTime, setScheduleTime] = useState(null)
    const [data, setData] = useState({
        id: '',
        recipients: '',
        course: '',
        message: '',
        arabic_message: '',
        list: '',
    })

    const [manufi_dt] = useState({
        dt_url:  `manufi-list`,
        dt_name: 'manufi_table',
        dt_column: [
            { data:"id", name:'id', title: "#No."},
            { data:"created_at", name:'created_at', title:"Time", searchable:false,},
            { data:"name", name:'users.name', title: "Recipients", searchable:false,},
            { data:"message", name:'message', title: "English Message"},
            { data:"arabic_message", name:'arabic_message', title: "Arabic Message"},
            { data:"status", name:'status', title: "Status"},
            { data:"id", title: 'Action', sortable:false, searchable:false, class:"text-end text-nowrap", width:160}
        ],
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                    {rowData.total_recipients === 1 && <span>{rowData.first_recipients}</span>}
                    {rowData.total_recipients === 2 && <span>{rowData.first_recipients}, {rowData.last_recipients}</span>}
                    {rowData.total_recipients > 2 && <span>{rowData.first_recipients}, {rowData.last_recipients}, ...</span>}
                    </>)
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <span dir='rtl'>{rowData.arabic_message}</span>
                    </>)
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) =>{
                    createRoot(td).render(<> 
                    { rowData.status === "delivered" ? <span className="text-green fw-medium">Delivered</span> : '' }
                    { rowData.status === "pending" ? <span className="text-warning fw-medium">Pending</span> : '' }
                    { rowData.status === "draft" ? <span className="text-danger fw-medium">Draft</span> : '' }
                    </>)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        {rowData.status !== "delivered" &&
                        <button type="button" className="btn btn-dark py-2px m-2px" title="Edit" data-bs-target="#createMessages" data-bs-toggle="modal" onClick={() => editCreateMessages(rowData.id)}>
                            Edit
                        </button>}

                        <button type="button" className="btn btn-primary py-2px m-2px" title="View" data-bs-target="#manufiRecipientsList" data-bs-toggle="modal" onClick={() => manufiRecipientsList(rowData.id)}>
                            View
                        </button>
                    </>)
                }
            },
        ]
    })

    useEffect(() => {
        if(listType === 'manufi'){
            setDraftList(false)
            reloadUrlDataTable(manufi_dt, `manufi-list`)

            fetchData(`get-lists`, 'GET', '', true, false, (res) => {
                if (res.status) {
                    setRecipientList(res.data)
                }
            }, (err) => {});
            fetchData(`get-courses`, 'GET', '', true, false, (res) => {
                if (res.status) {
                    setCourseList(res.data)
                }
            }, (err) => {});
        }
        document.getElementById("createMessages").addEventListener('hidden.bs.modal', function (e) {
            document.querySelectorAll(".invalid-feedback").forEach(function(e){e.remove()})
            initialFormState("createMessagesForm", {})
            setScheduleTimeEnable(false)
            setListSelect(false)
            setScheduleTime(null)
            setCourseSelect(false)
            setData({
                id: '',
                recipients: '',
                course: '',
                message: '',
                arabic_message: '',
                list: '',
                attachment: '',
            })
        })
    }, [listType, manufi_dt])

    const manufiRecipientsList = (id) => {
        let dt_recipients_list_details = {
            dt_url: `manufi-recipient-list/${id}`,
            dt_name: 'manufi_recipients_list',
            dt_column: [
                { data:"id", name:'id', title: "#ID"},
                { data:"name", name:'users.name', title: "Name"},
                { data:"email", name:'users.email', title: "Email"},
                { data:"phone", name:'user_details.phone', title: "Phone Number"},
                { data:"user_type", name:'roles.name', title: "User Type"},
                { data:"status", name:'status', title: "Delivery Status"},
            ],
        };
        reloadUrlDataTable(dt_recipients_list_details, `manufi-recipient-list/${id}`)
    }
    
    const manufiMessages = () => {
        let url = draftList ? `manufi-list` : `manufi-list?status_q=draft`
        setDraftList(! draftList)
        reloadUrlDataTable(manufi_dt, url)
    }

    const handleInputChange = (e) => {
        if(e.target.id === 'recipientsList'){
            setCourseSelect(false)
            document.querySelector("#recipientsList").childNodes.forEach(function(op){
                if(e.target.value === op.value){
                   if(op.getAttribute('data-id') === "1"){
                    setCourseSelect(true)
                    return
                   }
                }
            })
        }

        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value
        }))
        
    }

    const createOrUpdateMessage = (e, status = 'delivered') => {
        e.preventDefault();
        if(status === 'pending'){
            setScheduleTimeEnable(true)
            let ele = $('#schedule_time').val()
            if(!ele){
                return false
            }
        }
        if(validateForm(e, "createMessagesForm")){ 
            let url = data.id ? `update-manual-messages/${data.id}` : `send-manual-messages`;
            const formData = new FormData();
            if(listSelect){
                formData.append('list', data.list)
            }else{
                formData.append('recipients', data.recipients)
            }
            if(courseSelect){
                formData.append('course', data.course)
            }
            if(scheduleTime){
                formData.append('schedule_time', scheduleTime.toISOString())
            }
            if(data.attachment){
                formData.append('attachment', data.attachment)
            }
            formData.append('message', data.message)
            formData.append('arabic_message', data.arabic_message)
            formData.append('status', status)
            formData.append('status', status)
            formData.append('pathname', location.pathname)
            fetchData(url, 'POST', formData , true, true, (res) => {
                if(res.status){
                    let url = draftList ? `manufi-list?status_q=draft` : `manufi-list`
                    reloadUrlDataTable(manufi_dt, url)
                    document.querySelector("#createMessages [data-bs-dismiss=modal]").click()
                }
            }, (err) => {})
        }

    }

    const editCreateMessages = (id) => {
        fetchData(`update-manual-messages/${id}`, 'GET', '' , true, false, (res) => {
            if(res.status){
                setData(prevState => ({
                    ...prevState,
                    id: res.data.id,
                    recipients: res.data.user_id === 0 ? '' : res.data.user_id,
                    course: res.data.course_id,
                    message: res.data.message,
                    arabic_message: res.data.arabic_message,
                    list: res.data.list_id,
                }))
                if(res.data.delevered_on){
                    setScheduleTime(new Date(res.data.delevered_on))
                    setScheduleTimeEnable(true)
                }
                if(res.data.list_id){
                    setListSelect(true)
                }
                if(res.data.course_id){
                    setCourseSelect(true)
                }
            }
        }, (err) => {})
    }

    return(
        <>
            <div className="table-responsive p-1">
                <div className="text-end">
                    <button className="btn btn-blue mb-2 me-2" data-bs-target="#createMessages" data-bs-toggle="modal">Create Messages</button>
                    <button className="btn btn-warning mb-2 mmw-170px" onClick={manufiMessages}>{draftList ? 'Messages' : 'Draft Messages'}</button>
                </div>
                <Datatables dt_name="manufi_table"/>
            </div>

            <ModalSection title="Recipients List" modal_id="manufiRecipientsList" size="xl">
                <div className="table-responsive p-1">
                    <Datatables dt_name="manufi_recipients_list"/>
                </div>
            </ModalSection>

            <ModalSection title={data.id ? "Update Messages" : "Create Messages"} modal_id="createMessages" size="lg">
                <form action="#" method="post" className="needs-validation" id="createMessagesForm" noValidate>
                    <div className="row mx-0">
                        <label htmlFor='recipients' className="col-sm-4 col-form-label">Recipient / Recipient List<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-8 col-xxl-7">
                        <label> <input type="radio" checked={!listSelect} onChange={() => { setListSelect(false);  setCourseSelect(false); }}  /> Recipient </label>
                        <label className="p-1 me-3"> <input type="radio" checked={listSelect}  onChange={() => { setListSelect(true); setCourseSelect(false); }} />  Recipient List </label>
                        </div>
                    </div>
                    <div className="row mx-0 mb-2 mb-sm-4">
                        <label className="col-sm-4 col-form-label"></label>
                        <div className="col-sm-8 col-xxl-7">
                            {listSelect ? 
                                <select className="form-select" name="list" id="recipientsList" value={data.list} onChange={handleInputChange} required >
                                    <option value="">--- Select Recipients List---</option>
                                    {recipientList ? recipientList.map(item => (
                                        <option key={item.id} value={item.id} data-id={item.is_course}>{item.name}</option>
                                    )) : ''}
                                </select>
                            :   <input type='email' className="form-control" name='recipients' id='recipients' defaultValue={data.recipients} onChange={handleInputChange} required />}
                        </div>
                    </div>
                    {listSelect && courseSelect && 
                    <div className="row mx-0 mb-2 mb-sm-4">
                        <label className="col-sm-4 col-form-label">Course<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-8 col-xxl-7">
                            <select className="form-select" name="course" id="course" value={data.course} onChange={handleInputChange} required >
                                <option value="">--- Select Course---</option>
                                {courseList ? courseList.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )) : ''}
                            </select>
                        </div>
                    </div>}
                    <div className="row mx-0 mb-2 mb-sm-4">
                        <label htmlFor="message" className="col-sm-4 col-form-label">English Message<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-8 col-xxl-7">
                            <textarea className="form-control" id="message" name="message" defaultValue={data.message} required  rows={4} onChange={handleInputChange} /> 
                        </div>
                    </div>
                    <div className="row mx-0 mb-2 mb-sm-4">
                        <label htmlFor="arabic_message" className="col-sm-4 col-form-label">Arabic Message<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-8 col-xxl-7">
                            <textarea className="form-control" id="arabic_message" name="arabic_message" defaultValue={data.arabic_message} dir='rtl' required  rows={4} onChange={handleInputChange} /> 
                        </div>
                    </div>
                    <div className="row mx-0 mb-2 mb-sm-4">
                        <label htmlFor="arabic_message" className="col-sm-4 col-form-label">Attachments</label>
                        <div className="col-sm-8 col-xxl-7">
                        <input type='file' className="form-control" id='attachment' name="attachment" onChange={handleInputChange} />
                        </div>
                    </div>
                    {scheduleTimeEnable &&
                    <div className="row mx-0 mb-2 mb-sm-4">
                        <label className="col-sm-4 col-form-label">Schedule Time<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                        <div className="col-sm-8 col-xxl-7">
                            <DatePicker
                                selected={scheduleTime}
                                onChange={(e) => setScheduleTime(e)}
                                className="form-control"
                                minDate={new Date()}
                                timeInputLabel="Time:"
                                showTimeSelect
                                dateFormat="MMM dd, yyyy h:mm aa"
                                name="schedule_time"
                                id="schedule_time"
                                timeIntervals={1}
                                onChangeRaw={(e) => e.preventDefault()}
                            />
                            <div className={data.id === 'edit' ? 'd-none' : 'text-success'}>Please enter schedule date and time.</div>
                        </div>
                    </div>}
                    <div className="row mx-0">
                        <div className="col-sm-8 offset-sm-4">
                            {draftList ? <>
                                <button type="submit" className="btn btn-blue me-2 mb-2" onClick={createOrUpdateMessage}>{data.id ? `Publish` : `Submit`}</button>
                                <button type="submit" className="btn btn-warning me-2 mb-2" onClick={(e) => createOrUpdateMessage(e, 'pending')}>Schedule</button>
                                <button type="submit" className="btn btn-outline-success mb-2" onClick={(e) => createOrUpdateMessage(e, 'draft')}>Save Draft</button>
                            </> : <>
                                <button type="submit" className="btn btn-blue me-2 mb-2" onClick={createOrUpdateMessage}>{data.id ? `Update` : `Submit`}</button>
                                <button type="submit" className="btn btn-warning me-2 mb-2" onClick={(e) => createOrUpdateMessage(e, 'pending')}>Schedule</button>
                                {! data.id && <button type="submit" className="btn btn-outline-success mb-2" onClick={(e) => createOrUpdateMessage(e, 'draft')}>Save Draft</button>}
                            </>}
                        </div>
                    </div>
                </form>
            </ModalSection>
        </>
    )
}

export default Manufi
