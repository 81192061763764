import { useEffect,useState } from 'react';
import { Link } from "react-router-dom";
import { fetchData,  showAlert, scrollbarSetToTop} from "../components/Helper";
import FeedbackCard from "../components/cards/Feedback";
import CourseCarousel from '../components/cards/CourseCarousel';
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet";
import ErrorBoundary from '../components/ErrorBoundary';

function Home(){
    const [system, setSystem] = useState('');
    const [subject, setSubject] = useState('');
    const [systemId, setSystemId] = useState('');
    const [subjectId, setSubjectId] = useState('');
    const [search, setSearch] = useState(''); 
    const navigate = useNavigate();
    useEffect(() => {
        fetchData(`educational-classification-list/system`, 'GET', '', true, false, (res) => {
            setSystem(res.data)
        }, (err) => {}) 
        fetchData(`educational-classification-list/subject`, 'GET', '', true, false, (res) => {
            setSubject(res.data)
        }, (err) => {})
    }, [])
   
    useEffect(() => {
        scrollbarSetToTop(); 
   }, [])
   
   // scrollbarSetToTop()
    const findCourse = (e, search_q = false) => {
        localStorage.setItem('search', search);
        localStorage.setItem('system_id', systemId);
        localStorage.setItem('subject_id', subjectId);
        if(e) e.preventDefault(); 
        if(search.length >= 3 || (systemId) || (subjectId)){
            navigate(`/courses`) 
        }else{
            let msg = {message:'Please fill some record.', status:false}
            showAlert(msg)
        }
    }
    
    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach</title>
        </Helmet>
        <div id="carouselhome" className="carousel slide carousel-fade carousel-home carousel-noopacity" data-bs-ride="carousel" data-bs-pause="false">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselhome" data-bs-slide-to="0" className="active" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselhome" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselhome" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src="images/banner1.jpg" className="d-none d-md-block w-100" alt="weteach"/>
                    <img src="images/banner1-mobile.jpg" className="d-block d-md-none w-100" alt="weteach"/>
                    <div className="carousel-caption d-none">
                        <div>
                            <h5 className="display-6 lh-base mb-4">
                                You are just a step away from joining the best online <strong className="text-orange fw-bold">learning experience</strong>
                            </h5>
                            <Link to="about-us" className="btn btn-blue px-3 m-1" title='Learn More'>Learn More</Link>
                            <Link to="courses" className="btn btn-outline-blue px-3 m-1" title='See Courses'>See Courses</Link>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="images/banner2.jpg" className="d-none d-md-block w-100" alt="weteach"/>
                    <img src="images/banner2-mobile.jpg" className="d-block d-md-none w-100" alt="weteach"/>
                    <div className="carousel-caption d-none">
                        <div>
                            <h5 className="display-6 lh-base mb-4">
                                You are just a step away from joining the best online <strong className="text-blue fw-bold">learning experience</strong>
                            </h5>
                            <Link to="about-us" className="btn btn-orange px-3 m-1" title='Learn More'>Learn More</Link>
                            <Link to="courses" className="btn btn-outline-orange px-3 m-1" title='See Courses'>See Courses</Link>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="images/banner3.jpg" className="d-none d-md-block w-100" alt="weteach"/>
                    <img src="images/banner3-mobile.jpg" className="d-block d-md-none w-100" alt="weteach"/>
                    <div className="carousel-caption d-none">
                        <div>
                            <h5 className="display-6 lh-base mb-4">
                                You are just a step away from joining the best online <strong className="text-orange fw-bold">learning experience</strong>
                            </h5>
                            <Link to="about-us" className="btn btn-blue px-3 m-1" title='Learn More'>Learn More</Link>
                            <Link to="courses" className="btn btn-outline-blue px-3 m-1" title='See Courses'>See Courses</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="bg-blue py-5 position-relative overflow-hidden">
            <img src="images/courses-bg.png" className="position-absolute top-0 opacity-25 zindex-0 pointer-none" alt="weteach"/>
            <div className="container py-sm-4 position-relative zindex-2">
                <div className="row">
                    <div className="col-lg-12 pb-3">
                        <h3 className="heading-lines">Find Your Course</h3>
                    </div>
                </div>
                <form className="row form-searchrow g-3" action="#">
                    <div className="col">
                        <input type="text" className="form-control border-0 input-h46" id="search"   value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Course Keyword"/>
                    </div>
                    <div className="col">
                        <select className="form-select border-0 input-h46" title='System' aria-label="Default select example" value={systemId} onChange={(e) => setSystemId(e.target.value)} role="button">
                            <option value=''>---System---</option>
                            {
                                system ?  system.map((system_detail) => {
                                    return (<option key={system_detail.id} value={system_detail.id}>{system_detail.name}</option>)
                                }) :''  
                               
                            }
                        </select>
                    </div>
                    <div className="col">
                        <select className="form-select border-0 input-h46" title='Subject' aria-label="Default select example" value={subjectId} onChange={(e) => setSubjectId(e.target.value)} role="button">
                        <option value="">---Subject---</option>
                        {
                            subject ? subject.map((subject_detail) => {
                                return (<option key={subject_detail.id} value={subject_detail.id}>{subject_detail.name}</option>)
                            }):''  
                            
                        }
                        </select>
                    </div>
                    <div className="col-auto">
                        <button onClick={(e) => findCourse(e, true)} className="btn btn-orange input-h46 fw-medium px-4 w-100 border-0" title='Find Course'>Find Course ➜</button>
                    </div>
                </form>
            </div>
        </section>

        <section className="py-5">
            <div className="container py-sm-4">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="heading-lines">Featured Courses</h3>
                            <Link className="btn btn-outline-blue px-4" title="View All" to="/courses">View All</Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-12">
                    <ErrorBoundary>
                    <CourseCarousel/>
                    </ErrorBoundary>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-light py-5">
            <div className="container py-sm-4">
                <div className="row">
                    <div className="col-md-12 pb-3">
                        <h3 className="heading-lines">What Did We Achieve So Far ?</h3>
                    </div>
                </div>
                <div className="row g-4 text-center">
                    <div className="col-sm-6 col-lg-3">
                        <div className="shadow-sm rounded-10 bg-white p-4 h-100">
                            <img src="images/icons/hours.svg" className="w-80px" alt="weteach"/>
                            <p className="h5 fw-normal lh-base pt-3 opacity-60">
                                130000+ <br/> Teaching Hours.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="shadow-sm rounded-10 bg-white p-4 h-100">
                            <img src="images/icons/graduate-cap.svg" className="w-80px" alt="weteach"/>
                            <p className="h5 fw-normal lh-base pt-3 opacity-60">
                                2750+ <br/> Students from 11 countries.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="shadow-sm rounded-10 bg-white p-4 h-100">
                            <img src="images/icons/bookmark.svg" className="w-80px" alt="weteach"/>
                            <p className="h5 fw-normal lh-base pt-3 opacity-60">
                                180+ <br/> Educational Courses.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="shadow-sm rounded-10 bg-white p-4 h-100">
                            <img src="images/icons/user.svg" className="w-80px" alt="weteach"/>
                            <p className="h5 fw-normal lh-base pt-3 opacity-60">
                                50+ <br/> Professional Teachers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section> 

        <section className="py-5">
            <div className="container py-sm-4">
                <div className="row">
                    <div className="col-md-12 pb-3">
                        <h3 className="heading-lines">Our Students And Parents Feedback</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                    <ErrorBoundary>
                     <FeedbackCard /> 
                    </ErrorBoundary>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default Home
