import { Link, useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import { fetchData, validateForm } from "../../components/Helper";
import _ from 'lodash';
import { Helmet } from "react-helmet";

function HelpVideoEdit(){
    const navigate = useNavigate();
    let video_type = useParams().video_type
    const data = useLocation().state
    // const slugs = (video_type === 'parent' || video_type === 'student') ? ['my-profile', 'my-subjects', 'my-schedule', 'my-wallet', 'library', 'recordings', 'assignments', 'quizzes', 'performance'] : ['my-profile', 'my-groups','my-schedule','my-students','assignments','quizzes','notes', 'performance']
    const handleSubmitForm = (e) => {
        e.preventDefault();
        if(validateForm(e)) { 
          var formData = new FormData(document.getElementById('video-form'))
          formData.append('id', data.id)
            fetchData('help-videos-update', 'POST', formData, true, true, (res) => {
                if(res.status){
                    navigate(-1);
                }
            }, (err) => {})
        }
    }

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>WeTeach RR | Add { _.upperFirst(video_type) }</title>
        </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Update { _.upperFirst(video_type) } Video</h5>
                        </div>
                        <div className="col-auto mb-2">
                        <Link to={`/get-videos/${video_type}/list`} className="btn btn-blue px-3 mb-1 me-1" title={`${ _.upperFirst(video_type) } List`}> { _.upperFirst(video_type) } List</Link>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body p-sm-4 p-xl-5">
                                    <form action="#" method="post" id="video-form" className=" needs-validation" noValidate>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="role" className="col-sm-3 col-xxl-2 col-form-label">Role Type<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                              <input type="text" className="form-control" id="role" name="role" defaultValue={video_type} required readOnly/>
                                            </div>
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="slug" className="col-sm-3 col-xxl-2 col-form-label">Section Name<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                {/* <select className="form-select" name="slug" id="slug" defaultValue={data.slug} required>
                                                    <option value="">- Select -</option>
                                                    { slugs.map((ele, num) => <option value={ele} key={num}> { _.startCase(ele)}</option> )}
                                                </select> */}
                                                <input type="text" className="form-control" name="slug" id="slug" defaultValue={data.slug} required readOnly/>
                                            </div>
                                        </div>
                                        <div className="mb-2 mb-sm-4 row mx-0">
                                            <label htmlFor="embed_id" className="col-sm-3 col-xxl-2 col-form-label">Video URL<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                            <div className="col-sm-9 col-lg-6 col-xxl-5">
                                                <input type="text" className="form-control" id="embed_id" name="embed_id" defaultValue={data.embed_id} required/>
                                            </div>
                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-sm-9 offset-sm-3 offset-xxl-2">
                                                <button type="submit" className="btn btn-blue px-4 me-2" onClick={handleSubmitForm} title="Add">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HelpVideoEdit