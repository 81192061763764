import { createRoot } from 'react-dom/client';
import { useEffect, useState } from "react";
import Datatables, { initDataTable, redrawDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { fetchData, validateForm } from "../../components/Helper";
import $ from 'jquery';
import { Helmet } from 'react-helmet';

function ContactList(){

    const [dt] = useState({
        'dt_url': `contact-list?type=request`,
        'dt_name': 'contact',
        'dt_column': [
            { data:"id", name:'id', title: "#ID"},
            { data:"name", name:'name', title:"Name"},
            { data:"email", name:'email', title:"Email"},
            { data:"phone", name:'phone', title:"Phone No.", class:"text-nowrap"},
            { data:"description", name:'description',  title:"Description" },
            { data:"assigned_to", name:'users.name',  title:"Assigned To" },
            { data:"status", name:'status',  title:"Status" },
            { data:"id", title:"Action", sortable:false, searchable:false, class:"text-center", width:160}
        ],
        dt_column_defs: [
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                    { rowData.assigned_to === 0 ?  <span> Not Assigned Yet </span>: rowData.user }            
                    </>)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                    { rowData.status === "request" ? <span className="text-green fw-medium">Request</span> : '' }
                    { rowData.status === "in-progress" ? <span className="text-danger fw-medium">In Progress</span> : '' }
                    { rowData.status === "done" ? <span className="text-primary fw-medium">Done</span> : '' }                              
                    </>)
                },
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    createRoot(td).render(<>
                        <button type="button" className="btn btn-success mmw-100px py-2px m-2px" title="View" data-bs-toggle="modal" data-bs-target="#viewContact" onClick={() => openContactModal(rowData)}>
                            View
                        </button>                              
                    </>)
                },
            }
        ]
    })
    
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('')
    const [email, setEmail] = useState('');
    const [currentStatus, setCurrentStatus] = useState('');
    const [currentMessage, setCurrentMessage] = useState('');
    const [newStatus, setNewStatus] = useState('')
 
    const openContactModal = (rowData) => {
        setId(rowData.id)
        setName(rowData.name)
        setDescription(rowData.description)
        setEmail(rowData.email)
        setCurrentStatus(rowData.status)
        setCurrentMessage(rowData.message)
        document.getElementById('classForm').classList.remove('was-validated')
    }  
      const closeModel = () => {
        let id = document.getElementById('classForm')
        id.reset()
    }

    const handleSubmitForm = (e) => {
		e.preventDefault();
        if(validateForm(e)){
            let modalData = {
                id: id,
                name: name,
                description: description,
                email: email,
                reply:currentMessage,
                status: newStatus !== '' ? newStatus : currentStatus
            } 
            fetchData('contact-status-change', 'POST', modalData, true, false, (res) => {
                if(res.status) {
                    redrawDataTable(dt)
                    closeModel();
                    document.querySelector("#viewContact [data-bs-dismiss=modal]").click()
                    // $("#viewContact").modal('hide');
                    document.getElementById('classForm').classList.remove('was-validated')
                    if( newStatus && newStatus !== currentStatus){
                        newStatus === 'in-progress' ? $("#in-progress").trigger('click') : $("#done").trigger('click') ;
                    }
                }
            }, (err) => {})
        }
	}

    useEffect(() => {
        initDataTable(dt);
        document.querySelectorAll('button[data-bs-toggle="tab"]').forEach((e)=> {
            e.addEventListener('show.bs.tab', function (event) {
                reloadUrlDataTable(dt, `contact-list?type=${event.target.dataset.tablename}`)
            })
        })

    }, [dt])

    return(
        <>  
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeTeach | Contact Us</title>
            </Helmet>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col mb-2">
                            <h5 className="m-0 heading-lines fw-medium pb-2">Contact Us</h5>
                        </div>
                    </div>
                    <div className="row g-2 g-sm-3 g-lg-4">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue active fw-medium" data-bs-toggle="tab" data-bs-target="#request_box" type="button" role="tab" data-tablename="request" title='Request'>Request</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" data-bs-toggle="tab" data-bs-target="#inprogress_box" type="button" role="tab" id="in-progress" data-tablename="in-progress" title='In-progress'>In-progress</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link text-blue fw-medium" data-bs-toggle="tab" data-bs-target="#done_box" type="button" role="tab" id="done" data-tablename="done" title='Done'>Done</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content border border-top-0">
                                        <div className="tab-pane fade p-3 show active" role="tabpanel" >
                                            <div className="table-responsive p-3">
                                                <Datatables dt_name="contact"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Modal Box For View */}
            <div className="modal fade" id="viewContact" tabIndex="-1" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content border-0 shadow">
                        <div className="modal-header bg-light">
                            <h5 className="modal-title">Contact View</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModel} title='Close'></button>
                        </div>
                        <div className="modal-body p-sm-4">
                            <form action="#" className="needs-validation" method="post" id="classForm" noValidate>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="message" className="col-sm-4 col-form-label">Description<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <textarea className="form-control" rows="4" defaultValue={description} readOnly></textarea>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="message" className="col-sm-4 col-form-label">Reply<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <textarea className="form-control" rows="7" id="reply" name="reply" onChange={(e) => setCurrentMessage(e.target.value) } value={currentMessage ? currentMessage : ''} readOnly={currentStatus === 'done' ? true : false} required></textarea>
                                    </div>
                                </div>
                                <div className="row mx-0 mb-2 mb-sm-4">
                                    <label htmlFor="reference_class" className="col-sm-4 col-form-label" hidden={currentStatus === 'done' ? true : false}>Choose Status<sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                    <div className="col-sm-8 col-xxl-7">
                                        <select className="form-select" name="status" id="status" onChange={(e) => setNewStatus(e.target.value)} hidden={currentStatus === 'done' ? true : false} required>
                                            <option value={currentStatus} >{currentStatus === 'in-progress' ? 'In Progress' : 'Request'}</option>
                                            {
                                                (currentStatus === 'request') ? 
                                                <>
                                                <option value='in-progress'>In Progress</option>
                                                <option value='done'>Done</option></>
                                                :
                                                <option value='done'>Done</option>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-sm-8 offset-sm-4">
                                    {
                                        (currentStatus !== 'done') ? 
                                        <>
                                        <button type="button" className="btn btn-blue w-120px px-4 me-2" onClick={handleSubmitForm} title='Submit'>Submit</button>
                                        </>
                                        : ''
                                    }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
}
export default ContactList;
